import React from "react";
import { useForm, Controller } from "react-hook-form";
import Alert from "../alerts";
import Datetime from "react-datetime";
import MultiSelect from "react-multi-select-component";
import { FiX } from "react-icons/fi";

const FormValidation = ({ items, onSubmit, alerts, hidden, loading }) => {
  const { handleSubmit, control, errors, register } = useForm();
  const onSubmitFn = (data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };
  items = items.map((item) => {
    item["ref"] = register(item["error"]);
    return item;
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmitFn)}
      className="form flex flex-wrap w-full"
    >
      {alerts &&
        items.map((item, i) => {
          if (!errors[item.name]) return null;
          let msg = errors[item.name].message;
          if (msg.length === 0) msg = `${item.label} is required`;
          return (
            <div className="flex flex-col w-full">
              {errors[item.name] && (
                <div className="mb-2" key={i}>
                  <Alert
                    color="bg-transparent border-red-500 text-red-500"
                    borderLeft
                    raised
                  >
                    {msg}
                  </Alert>
                </div>
              )}
            </div>
          );
        })}
      <div className="w-full">
        {items.map((item, i) => {
          if (item.type === "checkbox") {
            return (
              <div className="form-element">
                {item.label && <div className="form-label">{item.label}</div>}
                <div className="items-center justify-start space-y-2">
                  {item.options.map((option, j) => (
                    <label className="flex items-center justify-start space-x-2">
                      <input
                        ref={item.ref}
                        type="checkbox"
                        value={option.value}
                        name={item.name}
                        className={`form-checkbox h-4 w-4 ${errors[item.name] ? "text-red-500" : ""
                          }`}
                      />
                      <span
                        className={`${errors[item.name] ? "text-red-500" : ""}`}
                      >
                        {option.label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            );
          }
          if (item.type === "radio") {
            return (
              <div className="form-element">
                {item.label && <div className="form-label">{item.label}</div>}
                <div className="flex items-center justify-start space-x-2">
                  {item.options.map((option, j) => (
                    <label className="flex items-center justify-start space-x-2">
                      <input
                        type="radio"
                        value={option.value}
                        name={item.name}
                        ref={register({ required: true })}
                        className={`form-radio h-4 w-4 ${errors[item.name] ? "text-red-500" : ""
                          }`}
                      />
                      <span
                        className={`${errors[item.name] ? "text-red-500" : ""}`}
                      >
                        {option.label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            );
          }
          if (item.type === "select") {
            return (
              <div className="form-element">
                {item.label && <div className="form-label">{item.label}</div>}
                <select
                  ref={item.ref}
                  name={item.name}
                  className={`form-select ${errors[item.name] ? "border border-red-500" : ""
                    }`}
                >
                  {item.options.map((option, j) => (
                    <option key={j} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            );
          }
          if (item.type === "textarea") {
            return (
              <>
                <div className="form-element">
                  {item.label && <div className="form-label">{item.label}</div>}
                  <textarea
                    ref={item.ref}
                    name={item.name}
                    className={`form-textarea ${errors[item.name] ? "border border-red-500" : ""
                      }`}
                    rows="3"
                    placeholder={item.placeholder}
                    value={item.value}
                  ></textarea>
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                </div>
              </>
            );
          }
          if (item.type === "datepicker") {
            return (
              <>
                <div className="form-element">
                  {item.label && <div className="form-label">{item.label}</div>}
                  <Controller
                    as={
                      <Datetime
                        timeFormat={false}
                        showTimeSelect={false}
                        dateFormat="DD-MM-YYYY"
                        input={true}
                        inputProps={{
                          className: "form-input",
                          placeholder: "Select date",
                        }}
                        viewMode={"days"}
                      />
                    }
                    defaultValue={new Date(item.value)}
                    ref={item.ref}
                    control={control}
                    name={item.name}
                    valueName="selected"
                  />
                </div>
              </>
            );
          }
          if (item.type === "tags") {
            return (
              <>
                <div className="form-element">
                  {item.label && <div className="form-label">{item.label}</div>}
                  <div className="flex items-center mb-2">
                    {item.value &&
                      item.value.map((tagItem, index) => {
                        return (
                          <span className="bg-blue-500 p-2 mr-2 rounded text-white flex items-center">
                            {tagItem}
                            <button
                              type="button"
                              onClick={() => item.onTagDelete(index)}
                            >
                              <FiX className="ml-1"></FiX>
                            </button>
                          </span>
                        );
                      })}
                  </div>
                  <input
                    ref={item.ref}
                    name={item.name}
                    type={item.type}
                    className={`form-input ${errors[item.name] ? "border-red-500" : ""
                      }`}
                    value={item.textValue}
                    placeholder={item.placeholder}
                    onChange={(e) => item.onChangeText(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        e.preventDefault();
                        item.onEnterPress();
                      }
                    }}
                  />
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                </div>
              </>
            );
          }
          if (item.type === "multiselect") {
            return (
              <div className="form-element">
                {item.label && (
                  <div className={`form-label ${item.hidden ? "hidden" : ""}`}>
                    {item.label}
                  </div>
                )}
                <MultiSelect
                  options={item.options}
                  value={item.value}
                  onChange={(e) => {
                    item.setSelectedItems(e)
                  }}
                />
              </div>
            )
          }
          return (
            <>
              <div className="form-element">
                {item.label && (
                  <div className={`form-label ${item.hidden ? "hidden" : ""}`}>
                    {item.label}
                  </div>
                )}
                <input
                  ref={item.ref}
                  name={item.name}
                  type={item.type}
                  className={`${errors[item.name] ? "border-red-500" : ""
                    } ${item.hidden ? "hidden" : "form-input"}`}
                  placeholder={item.placeholder}
                  value={item.value}
                  disabled={item.disabled}
                  onChange={(e) => item.onChangeText && item.onChangeText(e.target.value)}
                />
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            </>
          );
        })}
      </div>
      <input
        type="submit"
        disabled={loading}
        className={`btn btn-default ${loading ? "bg-gray-200" : "bg-blue-500 hover:bg-blue-600"} text-white btn-rounded mt-4`}
      />
    </form>
  );
};
export default FormValidation;
