import React from 'react'
import {
  FiToggleLeft,
  FiList,
  FiActivity,
  FiCalendar,
  FiStar,
  FiDroplet,
  FiGrid,
  FiClock,
  FiCopy,
  FiUser,
  FiPieChart,
  FiMap,
  FiCompass,
  FiHelpCircle,
  FiShoppingCart,
  FiHome,
  FiShoppingBag,
  FiGift,
  FiLogOut,
  FiCheckCircle,
  FiArchive,
  FiSearch,
  FiClipboard,
  FiBarChart2,
  FiImage,
  FiBookOpen,
  FiDollarSign,
  FiFolder,
  FiSettings,
  FiUsers
} from 'react-icons/fi'

const initialState = [
  {
    //title: 'Home',
    items: [
      {
        url: '/dashboard',
        icon: <FiCompass size={20} />,
        title: 'Dashboard',
        items: []
      },
    ]
  },
  // {
  //   title: 'E-Commerce',
  //   items: [
  //     {
  //       url: '/',
  //       icon: <FiList size={20} />,
  //       title: 'Master Data',
  //       items: [
  //         {
  //           url: '/master/merchants',
  //           title: 'Merchants',
  //           items: []
  //         },
  //         {
  //           url: '/master/items',
  //           title: 'Items / SKUs',
  //           items: []
  //         }
  //       ]
  //     },
  //     {
  //       url: '/offer/list',
  //       icon: <FiShoppingBag size={20} />,
  //       title: 'Offer Setup',
  //       items: []
  //     },
  //     {
  //       url: '/purchase/list',
  //       icon: <FiShoppingCart size={20} />,
  //       title: 'Purchase List',
  //       items: []
  //     },
  //   ]
  // },
  // {
  //   title: 'Activity',
  //   items: [
  //     {
  //       url: '/verify/activity',
  //       icon: <FiCheckCircle size={20} />,
  //       title: 'Verification',
  //       items: []
  //     },
  //     {
  //       url: '/activity/list',
  //       icon: <FiClipboard size={20} />,
  //       title: 'Activity List',
  //       items: []
  //     },
  //     {
  //       url: '/search',
  //       icon: <FiSearch size={20} />,
  //       title: 'Activity Search',
  //       items: []
  //     },
  //     {
  //       url: '/leaderboard/list',
  //       icon: <FiBarChart2 size={20} />,
  //       title: 'Leaderboard',
  //       items: []
  //     }
  //   ]
  // },
  // {
  //   title: 'Training',
  //   items: [
  //     {
  //       url: '/',
  //       icon: <FiList size={20} />,
  //       title: 'Library',
  //       items: [
  //         {
  //           url: '/master/training/exercise',
  //           title: 'Exercises',
  //           items: []
  //         },
  //         {
  //           url: '/master/training/warmup',
  //           title: 'Warmups',
  //           items: []
  //         },
  //         {
  //           url: '/master/training/cooldown',
  //           title: 'Cooldowns',
  //           items: []
  //         },
  //         {
  //           url: '/master/library/metric',
  //           title: 'Metrics',
  //           items: []
  //         },
  //         {
  //           url: '/master/library/document',
  //           title: 'Documents',
  //           items: []
  //         }
  //       ]
  //     },
  //     {
  //       url: '/training/program',
  //       icon: <FiFolder size={20} />,
  //       title: 'Training Menu',
  //       items: []
  //     },
  //     {
  //       url: '/',
  //       icon: <FiUsers size={20} />,
  //       title: 'Management',
  //       items: [
  //         {
  //           url: '/training/teams/manage',
  //           title: 'Teams',
  //           items: []
  //         },
  //         {
  //           url: '/training/clients/manage',
  //           title: 'Clients',
  //           items: []
  //         },
  //         {
  //           url: '/training/client-groups/manage',
  //           title: 'Client Groups',
  //           items: []
  //         },
  //       ]
  //     },
  //   ]
  // },
  {
    title: 'Fotografer',
    items: [
      {
        url: '/gallery/list',
        icon: <FiGrid size={20} />,
        title: 'Gallery List',
        items: []
      }
    ],
    items: [
      {
        url: '/gallery/folder',
        icon: <FiFolder size={20} />,
        title: 'Pengelolaan Folder Foto',
        items: []
      },
      {
        url: '/gallery/upload',
        icon: <FiImage size={20} />,
        title: 'Upload ke Galeri',
        items: []
      },
      // {
      //   url: '/gallery/manage',
      //   icon: <FiBookOpen size={20} />,
      //   title: 'Pengelolaan Galeri',
      //   items: []
      // },
      {
        url: '/gallery/report',
        icon: <FiDollarSign size={20} />,
        title: 'Laporan Pendapatan',
        items: []
      }
    ]
  },
  // {
  //   title: 'Payment',
  //   items: [
  //     {
  //       url: '/verify/list',
  //       icon: <FiCheckCircle size={20} />,
  //       title: 'Verification',
  //       items: []
  //     }
  //   ]
  // },
  // {
  //   title: 'Components',
  //   items: [
  //     {
  //       url: '/',
  //       icon: <FiDroplet size={20} />,
  //       title: 'UI Elements',
  //       items: [
  //         {
  //           url: '/badges',
  //           title: 'Badges',
  //           items: []
  //         },
  //         {
  //           url: '/breadcrumbs',
  //           title: 'Breadcrumbs',
  //           items: []
  //         },
  //         {
  //           url: '/buttons',
  //           title: 'Buttons',
  //           items: []
  //         },
  //         {
  //           url: '/dropdowns',
  //           title: 'Dropdowns',
  //           items: []
  //         },
  //         {
  //           url: '/images',
  //           title: 'Images',
  //           items: []
  //         },
  //         {
  //           url: '/lists',
  //           title: 'Lists',
  //           items: []
  //         },
  //         {
  //           url: '/progress-bars',
  //           title: 'Progress bars',
  //           items: []
  //         },
  //         {
  //           url: '/pagination',
  //           title: 'Pagination',
  //           items: []
  //         },
  //         {
  //           url: '/tabs',
  //           title: 'Tabs',
  //           items: []
  //         },
  //         {
  //           url: '/typography',
  //           title: 'Typography',
  //           items: []
  //         }
  //       ]
  //     },
  //     {
  //       url: '/',
  //       icon: <FiCalendar size={20} />,
  //       title: 'Forms',
  //       badge: {
  //         color: 'bg-indigo-500 text-white',
  //         text: 6
  //       },
  //       items: [
  //         {
  //           url: '/default-forms',
  //           title: 'Default forms',
  //           items: []
  //         },
  //         {
  //           url: '/sample-forms',
  //           title: 'Sample forms',
  //           items: []
  //         },
  //         {
  //           url: '/text-editor',
  //           title: 'Text editor',
  //           items: []
  //         },
  //         {
  //           url: '/file-uploads',
  //           title: 'File uploads',
  //           items: []
  //         },
  //         {
  //           url: '/sliders',
  //           title: 'Sliders',
  //           items: []
  //         },
  //         {
  //           url: '/datepicker',
  //           title: 'Date picker',
  //           items: []
  //         },
  //         {
  //           url: '/switches',
  //           title: 'Switches',
  //           items: []
  //         },
  //         {
  //           url: '/steps',
  //           title: 'Form steps',
  //           items: []
  //         },
  //         {
  //           url: '/validation',
  //           title: 'Form validation',
  //           items: []
  //         }
  //       ]
  //     },
  //     {
  //       url: '/',
  //       icon: <FiGrid size={20} />,
  //       title: 'Tables',
  //       items: [
  //         {
  //           url: '/default-tables',
  //           title: 'Default tables',
  //           items: []
  //         },
  //         {
  //           url: '/datatables',
  //           title: 'Datatables',
  //           items: []
  //         }
  //       ]
  //     },
  //     {
  //       url: '/',
  //       icon: <FiClock size={20} />,
  //       title: 'Notifications',
  //       badge: {
  //         color: 'bg-indigo-500 text-white',
  //         text: 2
  //       },
  //       items: [
  //         {
  //           url: '/alerts',
  //           title: 'Alerts',
  //           items: []
  //         },
  //         {
  //           url: '/notifications',
  //           title: 'Notifications',
  //           items: []
  //         },
  //         {
  //           url: '/modals',
  //           title: 'Modals',
  //           items: []
  //         },
  //         {
  //           url: '/popovers',
  //           title: 'Popovers',
  //           items: []
  //         },
  //         {
  //           url: '/tooltips',
  //           title: 'Tooltips',
  //           items: []
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: 'Pages',
  //   items: [
  //     {
  //       url: '/',
  //       icon: <FiCopy size={20} />,
  //       title: 'Authentication',
  //       badge: {
  //         color: 'bg-indigo-500 text-white',
  //         text: 7
  //       },
  //       items: [
  //         {
  //           url: '/contact-us-1',
  //           title: 'Contact us',
  //           items: []
  //         },
  //         {
  //           url: '/login-1',
  //           title: 'Login 1',
  //           items: []
  //         },
  //         {
  //           url: '/login-2',
  //           title: 'Login 2',
  //           items: []
  //         },
  //         {
  //           url: '/login-3',
  //           title: 'Login 3',
  //           items: []
  //         },
  //         {
  //           url: '/create-account',
  //           title: 'Create account',
  //           items: []
  //         },
  //         {
  //           url: '/email-confirmation',
  //           title: 'Email confirmation',
  //           items: []
  //         },
  //         {
  //           url: '/logout',
  //           title: 'Logout',
  //           items: []
  //         },
  //         {
  //           url: '/reset-password',
  //           title: 'Reset password',
  //           items: []
  //         },
  //         {
  //           url: '/forgot-password',
  //           title: 'Forgot password',
  //           items: []
  //         },
  //         {
  //           url: '/lock-screen',
  //           title: 'Lock screen',
  //           items: []
  //         },
  //         {
  //           url: '/subscribe',
  //           title: 'Subscribe',
  //           items: []
  //         }
  //       ]
  //     },
  //     {
  //       url: '/',
  //       icon: <FiUser size={20} />,
  //       title: 'User',
  //       items: [
  //         {
  //           url: '/user-profile',
  //           title: 'User profile',
  //           items: []
  //         },
  //         {
  //           url: '/social-feed',
  //           title: 'Social feed',
  //           items: []
  //         }
  //       ]
  //     },
  //     {
  //       url: '/',
  //       icon: <FiClock size={20} />,
  //       title: 'Pages',
  //       items: [
  //         {
  //           url: '/support-1',
  //           title: 'Support',
  //           items: []
  //         },
  //         {
  //           url: '/empty-page',
  //           title: 'Empty page',
  //           items: []
  //         },
  //         {
  //           url: '/terms-of-service',
  //           title: 'Terms of service',
  //           items: []
  //         },
  //         {
  //           url: '/privacy-policy',
  //           title: 'Privacy policy',
  //           items: []
  //         },
  //         {
  //           url: '/error-page',
  //           title: 'Error page',
  //           items: []
  //         },
  //         {
  //           url: '/coming-soon',
  //           title: 'Coming soon',
  //           items: []
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: 'Other',
  //   items: [
  //     {
  //       url: '/charts',
  //       icon: <FiPieChart size={20} />,
  //       title: 'Charts',
  //       badge: {
  //         color: 'bg-indigo-500 text-white',
  //         text: 4
  //       },
  //       items: [
  //         {
  //           url: '/bar-charts',
  //           title: 'Bar charts',
  //           items: []
  //         },
  //         {
  //           url: '/line-charts',
  //           title: 'Line and area charts',
  //           items: []
  //         },
  //         {
  //           url: '/pie-charts',
  //           title: 'Pie and doughnut charts',
  //           items: []
  //         },
  //         {
  //           url: '/other-charts',
  //           title: 'Other charts',
  //           items: []
  //         }
  //       ]
  //     },
  //     {
  //       url: '/',
  //       icon: <FiMap size={20} />,
  //       title: 'Maps',
  //       items: [
  //         {
  //           url: '/leaflet-maps',
  //           title: 'Leaflet maps',
  //           items: []
  //         },
  //         {
  //           url: '/vector-maps',
  //           title: 'Vector maps',
  //           items: []
  //         }
  //       ]
  //     },
  //     {
  //       url: '/',
  //       icon: <FiToggleLeft size={20} />,
  //       title: 'Icons',
  //       items: [
  //         {
  //           url: '/react-icons',
  //           title: 'React icons',
  //           items: []
  //         },
  //         {
  //           url: '/country-flags',
  //           title: 'Country flags',
  //           items: []
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: 'Docs',
  //   items: [
  //     {
  //       url: '/documentation',
  //       icon: <FiHelpCircle size={20} />,
  //       title: 'Documentation',
  //       items: []
  //     }
  //   ]
  // },
  // {
  //   title: 'Settings',
  //   items: [
  //     {
  //       url: '/landing',
  //       icon: <FiLogOut size={20} />,
  //       title: 'Logout',
  //       items: []
  //     }
  //   ]
  // }
]

export default function navigation(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
