import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import SectionTitle from "../../components/section-title";
import clients from "../../json/clients.json";
import Widget from "../../components/widget";
import Modal4 from "../../components/modals/modal-4";
import Validation from "../../components/forms/validation";
import { FiCheck } from "react-icons/fi";
import { resetUpdateFileTagProcess, updateFileTag } from "../../actions";

const Index = () => {
  let dispatch = useDispatch();
  let history = useHistory();

  const updateSuccess = useSelector((state) => state.gallery.updateTagSuccess);
  const [data1, onSubmit1] = useState(null);
  const [members, setMembers] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const options = []

  clients.map(client => {
    const { id, name } = client;
    options.push({ label: name, value: id })
  })

  useEffect(() => {
    if (updateSuccess) {
      setModalOpen(true)
    }
  }, [updateSuccess])

  let items = [
    {
      label: "Name",
      error: { required: "Please enter a valid name" },
      name: "name",
      type: "text",
      placeholder: "Enter name of your client group",
    },
    {
      label: "Members",
      // error: { required: "Please enter a valid name" },
      name: "members",
      type: "multiselect",
      placeholder: "Choose members for this group",
      options: options,
      value: members,
      setSelectedItems: setMembers
    }
  ];

  return (
    <>
      <SectionTitle title="Gallery" subtitle="Manage Object" />

      <Widget
        // title="Manage Object Information"
        description={<span>Manage Object Information</span>}
      >
        <div className="flex flex-col lg:flex-row lg:flex-wrap w-full lg:space-x-4">
          <div className="w-full lg:w-1/2 lg:p-2">
            <Validation
              items={items}
              onSubmit={onSubmit1}
              alerts={false}
            />
          </div>
          <div className="w-full lg:w-1/2 lg:p-2">
            {data1 && <pre>{JSON.stringify({ ...data1, members }, null, 2)}</pre>}
          </div>
        </div>

        <Modal4
          title="Update tag successful"
          icon={
            <span className="h-12 w-12 mx-auto my-4 bg-green-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
              <FiCheck size={18} className="stroke-current text-green-500" />
            </span>
          }
          body={
            <div className="text-sm text-grey-500">
              You have been successfully changed the tag for this object. Click
              on Go Back button below to go back to the list page.
            </div>
          }
          buttonTitle="Go back"
          buttonClassName="btn btn-default btn-rounded bg-blue-500 text-white hover:bg-blue-600 w-full"
          open={modalOpen}
          onClickAction={() => {
            dispatch(resetUpdateFileTagProcess())
            history.push("/gallery/manage");
          }}
          onCloseAction={() => {
            setModalOpen(false)
          }}
        />
      </Widget>
    </>
  );
};
export default Index;
