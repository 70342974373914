import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import SectionTitle from "../../components/section-title";
import Widget from "../../components/widget";
import Modal4 from "../../components/modals/modal-4";
import Validation from "../../components/forms/validation";
import { FiCheck } from "react-icons/fi";
import { resetUpdateFileTagProcess, updateFileTag, fetchImageList } from "../../actions";

const Index = () => {
  let dispatch = useDispatch();
  let history = useHistory();

  let { bucketName, fileName } = useParams();
  const listItems = useSelector((state) => state.gallery.imageList);

  const currentItem = listItems.find(
    (x) => x.bucket === bucketName && x.name === fileName
  );

  const updateSuccess = useSelector((state) => state.gallery.updateTagSuccess);
  const [data1, onSubmit1] = useState(null);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const username = useSelector((state) => state.user.username);

  useEffect(() => {
    if (updateSuccess) {
      setModalOpen(true);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if(listItems && listItems.length > 0) {
      if (currentItem && currentItem.tag && currentItem.tag.length > 0 && currentItem.tag.indexOf(",") > -1) {
        setTags(currentItem.tag.split(","))
      } else if (currentItem && currentItem.tag && currentItem.tag.length > 0) {
        setTags(currentItem.tag)
      }
  
    }    
  }, [listItems, currentItem])

  let items = [
    {
      label: "Nama File",
      error: { required: "Mohon masukkan nama yang valid" },
      name: "fileName",
      type: "text",
      // disabled: true,
      placeholder: "Masukkan nama",
      value: fileName,
    },
    {
      label: "Tag Kategori",
      name: "category",
      type: "tags",
      placeholder:
        "Masukkan tag untuk mengkategorikan foto. Tekan Enter untuk menyimpan tag",
      value: tags,
      textValue: newTag,
      onEnterPress: () => {
        var newTags = [...tags, newTag];
        setTags(newTags);
        setNewTag("");
      },
      onChangeText: (newText) => {
        setNewTag(newText);
      },
      onTagDelete: (index) => {
        var newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);
      },
    },
    {
      label: "Hidden Bucket Name",
      name: "bucketName",
      type: "text",
      hidden: true,
      value: bucketName,
    },
    {
      label: "Hidden Tag",
      name: "newTag",
      type: "text",
      hidden: true,
      value: tags && tags.join(),
    },
  ];

  return (
    <>
      <SectionTitle title="Galeri" subtitle="Kelola Foto" />

      <Widget
        // title="Manage Object Information"
        description={<span>Kelola Informasi Foto</span>}
      >
        <div className="flex flex-col lg:flex-row lg:flex-wrap w-full lg:space-x-4">
          <img
            src={`https://storage.googleapis.com/${bucketName}/${fileName}`}
            className="max-w-lg object-contain max-h-screen"
          />
          <div className="w-full lg:w-1/2 lg:p-2">
            <Validation
              items={items}
              onSubmit={(e) => {
                dispatch(updateFileTag(e));
              }}
              alerts={false}
            />
            <button onClick={() => history.goBack()} className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded mt-4">Kembali</button>
          </div>
          <div className="w-full lg:w-1/2 lg:p-2">
            {data1 && <pre>{JSON.stringify(data1, null, 2)}</pre>}
          </div>
        </div>

        <div className="flex">
          <Modal4
            title="Update tag berhasil"
            icon={
              <span className="h-12 w-12 mx-auto my-4 bg-green-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                <FiCheck size={18} className="stroke-current text-green-500" />
              </span>
            }
            body={
              <div className="text-sm text-grey-500">
                Kamu telah berhasil mengubah tag untuk foto ini. Klik tombol
                Kembali untuk kembali ke halaman daftar.
              </div>
            }
            buttonTitle="Kembali"
            buttonClassName="btn btn-default btn-rounded bg-blue-500 text-white hover:bg-blue-600 w-full"
            open={modalOpen}
            onClickAction={() => {
              dispatch(resetUpdateFileTagProcess());
              history.goBack()
            }}
            onCloseAction={() => {
              setModalOpen(false);
            }}
          />
        </div>
      </Widget>
    </>
  );
};
export default Index;
