import axios from "axios";
import {
  FETCH_EVENT_BUCKET_COMMENCED,
  FETCH_EVENT_BUCKET_SUCCESS,
  FETCH_EVENT_BUCKET_FAILURE,
  UPLOAD_IMAGE_TO_BUCKET_COMMENCED,
  UPLOAD_IMAGE_TO_BUCKET_SUCCESS,
  UPLOAD_IMAGE_TO_BUCKET_FAILURE,
  FETCH_IMAGE_LIST_COMMENCED,
  FETCH_IMAGE_LIST_SUCCESS,
  FETCH_IMAGE_LIST_FAILURE,
  UPDATE_IMAGE_TAG_COMMENCED,
  UPDATE_IMAGE_TAG_SUCCESS,
  UPDATE_IMAGE_TAG_FAILURE,
  DELETE_FILE_FROM_BUCKET_COMMENCED,
  DELETE_FILE_FROM_BUCKET_SUCCESS,
  DELETE_FILE_FROM_BUCKET_FAILURE,
  FETCH_PHOTOGRAPHER_REPORT_COMMENCED,
  FETCH_PHOTOGRAPHER_REPORT_SUCCESS,
  FETCH_PHOTOGRAPHER_REPORT_FAILURE,
  FETCH_FOLDERS_COMMENCED,
  FETCH_FOLDERS_SUCCESS,
  FETCH_FOLDERS_FAILURE,
  ADD_FOLDER_DATA,
  UPDATE_FOLDER_DATA,
  DELETE_FOLDER_DATA,
  FETCH_PHOTOGRAPHER_REPORT_DETAIL_COMMENCED,
  FETCH_PHOTOGRAPHER_REPORT_DETAIL_SUCCESS,
  FETCH_PHOTOGRAPHER_REPORT_DETAIL_FAILURE,
  DELETE_IMAGE_SUCCESS,
  RESET_IMAGE_LIST,
  REMOVE_FROM_UPLOADED_LIST_BY_INDEX,
  RESET_UPLOADED_LIST
} from "./types";

export const fetchFolderList = (slug) => {
  let url = `https://aktivin.et.r.appspot.com/gallery/folder/list-all/${slug}`;

  return (dispatch) => {
    dispatch({ type: FETCH_FOLDERS_COMMENCED });
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((list) => {
        fetchFolderListSuccess(dispatch, list.data);
      })
      .catch(() => fetchFolderListFailure(dispatch));
  };
};

const fetchFolderListSuccess = (dispatch, list) => {
  dispatch({ type: FETCH_FOLDERS_SUCCESS, payload: list });
};

const fetchFolderListFailure = (dispatch) => {
  dispatch({ type: FETCH_FOLDERS_FAILURE });
};

export const addFolder = (newFolder, slug) => {
  let url = `https://aktivin.et.r.appspot.com/gallery/folder/add/${slug}`;

  return (dispatch) => {
    dispatch({ type: ADD_FOLDER_DATA, payload: newFolder });
    axios
      .post(url, newFolder, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((list) => {
        fetchFolderListSuccess(dispatch, list.data);
      })
      .catch(() => fetchFolderListFailure(dispatch));
  };
};

export const updateFolderName = (newFolder, slug) => {
  let url = `https://aktivin.et.r.appspot.com/gallery/folder/update/${slug}`;

  return (dispatch) => {
    dispatch({ type: FETCH_FOLDERS_COMMENCED });
    axios
      .post(url, newFolder, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((list) => {
        fetchFolderListSuccess(dispatch, list.data);
      })
      .catch(() => fetchFolderListFailure(dispatch));
  };
};

export const deleteFolder = (folderId) => {
  let url = `https://aktivin.et.r.appspot.com/gallery/folder/delete/${folderId}`;

  return (dispatch) => {
    dispatch({ type: DELETE_FOLDER_DATA, payload: folderId });
    axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((list) => {
        fetchFolderListSuccess(dispatch, list.data);
      })
      .catch(() => fetchFolderListFailure(dispatch));
  };
};

export const fetchEventListAndBuckets = (page) => {
  let url = `https://aktivin.et.r.appspot.com/gallery/event/list-all`;

  return (dispatch) => {
    dispatch({ type: FETCH_EVENT_BUCKET_COMMENCED });
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((list) => {
        fetchEventListAndBucketsSuccess(dispatch, list.data);
      })
      .catch(() => fetchEventListAndBucketsFailure(dispatch));
  };
};

const fetchEventListAndBucketsSuccess = (dispatch, list) => {
  dispatch({ type: FETCH_EVENT_BUCKET_SUCCESS, payload: list });
};

const fetchEventListAndBucketsFailure = (dispatch) => {
  dispatch({ type: FETCH_EVENT_BUCKET_FAILURE });
};

export const removeItemFromList = (index) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_FROM_UPLOADED_LIST_BY_INDEX, payload: index });
  }
}

export const uploadImageToBucket = (
  file,
  bucketName,
  photographerSlug,
  tags,
  folderId
) => {
  let url = `https://aktivin.et.r.appspot.com/image-ml/upload-gallery`;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("bucketName", bucketName);
  formData.append("folderId", folderId);
  formData.append("folderTags", tags.toString());
  formData.append("photographerSlug", photographerSlug);

  return (dispatch) => {
    dispatch({ type: UPLOAD_IMAGE_TO_BUCKET_COMMENCED, payload: file });

    if (file.size > 7e6) {
      uploadImageToBucketFailure(dispatch, file, "Error: Ukuran foto lebih besar dari 7 MB");
    } else {
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((list) => {
          console.log(list.data)
          list.data.error
            ? uploadImageToBucketFailure(dispatch, file, list.data.errorMsg)
            : uploadImageToBucketSuccess(dispatch, list.data.data);
        })
        .catch((ex) => {
          uploadImageToBucketFailure(dispatch, file, ex);
        });
    }
  };
};

const uploadImageToBucketSuccess = (dispatch, list) => {
  console.log(list)
  dispatch({ type: UPLOAD_IMAGE_TO_BUCKET_SUCCESS, payload: list });
};

const uploadImageToBucketFailure = (dispatch, file, errorMsg) => {
  dispatch({
    type: UPLOAD_IMAGE_TO_BUCKET_FAILURE,
    payload: { file, errorMsg },
  });
};

export const fetchImageList = (photographerSlug) => {
  let url = `https://aktivin.et.r.appspot.com/image-ml/photographer/list-photo/${photographerSlug}`;

  return (dispatch) => {
    dispatch({ type: FETCH_IMAGE_LIST_COMMENCED });
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((list) => {
        list.data.error
          ? fetchImageListFailure(dispatch)
          : fetchImageListSuccess(dispatch, list.data.data);
      })
      .catch(() => fetchImageListFailure(dispatch));
  };
};

export const fetchImageListByFolder = (bucketName, folderId, page) => {
  let url = `https://aktivin.et.r.appspot.com/image-ml/photographer/list-photo-by-folder`;
  
  return (dispatch) => {
    dispatch({ type: FETCH_IMAGE_LIST_COMMENCED });
    axios
      .post(url, { bucketName, folderId, page }, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((list) => {
        list.data.error
          ? fetchImageListFailure(dispatch)
          : fetchImageListSuccess(dispatch, list.data.data);
      })
      .catch(() => fetchImageListFailure(dispatch));
  };
};

const fetchImageListSuccess = (dispatch, list) => {
  dispatch({ type: FETCH_IMAGE_LIST_SUCCESS, payload: list });
};

const fetchImageListFailure = (dispatch) => {
  dispatch({ type: FETCH_IMAGE_LIST_FAILURE });
};

export const updateFileTag = ({ bucketName, fileName, newTag }) => {
  let url = `https://aktivin.et.r.appspot.com/image-ml/photographer/edit-tag-photo`;

  const myObject = {
    bucketName,
    fileName,
    newTag: newTag.split(','),
  };

  return (dispatch) => {
    dispatch({ type: UPDATE_IMAGE_TAG_COMMENCED });
    axios
      .post(url, myObject, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((list) => {
        list.data.error
          ? updateFileTagFailure(dispatch)
          : updateFileTagSuccess(dispatch, myObject);
      })
      .catch(() => updateFileTagFailure(dispatch));
  };
};

const updateFileTagSuccess = (dispatch, data) => {
  dispatch({ type: UPDATE_IMAGE_TAG_SUCCESS, payload: data });
};

const updateFileTagFailure = (dispatch) => {
  dispatch({ type: UPDATE_IMAGE_TAG_FAILURE });
};

export const resetUpdateFileTagProcess = () => {
  return (dispatch) => {
    dispatch({ type: UPDATE_IMAGE_TAG_FAILURE });
  };
};

export const resetUploadImageList = () => {
  return (dispatch) => {
    dispatch({ type: RESET_UPLOADED_LIST });
  };
};

export const deleteFileFromBucket = (selectedItems, photographerSlug) => {
  let url = `https://aktivin.et.r.appspot.com/image-ml/photographer/delete-photo`;

  return (dispatch) => {
    axios
      .post(
        url,
        { deleteItems: selectedItems },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        dispatch({ type: DELETE_IMAGE_SUCCESS, payload: selectedItems });
      })
      .catch(() => fetchImageListFailure(dispatch));
  };
};

export const fetchPhotographerReport = (slug) => {
  let url = `https://aktivin.et.r.appspot.com/gallery/report`;

  return (dispatch) => {
    dispatch({ type: FETCH_PHOTOGRAPHER_REPORT_COMMENCED });
    axios
      .post(
        url,
        { slug },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((list) => {
        fetchPhotographerReportSuccess(dispatch, list.data);
      })
      .catch(() => fetchPhotographerReportFailure(dispatch));
  };
};

const fetchPhotographerReportSuccess = (dispatch, list) => {
  dispatch({ type: FETCH_PHOTOGRAPHER_REPORT_SUCCESS, payload: list });
};

const fetchPhotographerReportFailure = (dispatch) => {
  dispatch({ type: FETCH_PHOTOGRAPHER_REPORT_FAILURE });
};

export const fetchPhotographerReportDetail = (slug, date) => {
  let url = `https://aktivin.et.r.appspot.com/gallery/report-detail`;

  return (dispatch) => {
    dispatch({ type: FETCH_PHOTOGRAPHER_REPORT_DETAIL_COMMENCED });
    axios
      .post(
        url,
        { slug, date },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((list) => {
        fetchPhotographerReportDetailSuccess(dispatch, list.data);
      })
      .catch(() => fetchPhotographerReportDetailFailure(dispatch));
  };
};

const fetchPhotographerReportDetailSuccess = (dispatch, list) => {
  dispatch({ type: FETCH_PHOTOGRAPHER_REPORT_DETAIL_SUCCESS, payload: list });
};

const fetchPhotographerReportDetailFailure = (dispatch) => {
  dispatch({ type: FETCH_PHOTOGRAPHER_REPORT_DETAIL_FAILURE });
};

export const resetImageList = () => {
  return (dispatch) => {
    dispatch({ type: RESET_IMAGE_LIST })
  }
}