import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import SectionTitle from "../../components/section-title";
import Datatable from "../../components/datatable";
import exercises from "../../json/master/exercise.json";
import Widget from "../../components/widget";
import Dropdown from "../../components/dropdowns/dropdown-1";
import DropdownItem from "../../components/dropdowns/dropdownItem";
import { FiEdit, FiTrash, FiMoreVertical, FiChevronDown } from "react-icons/fi";
import { formatNumber } from "../../functions/numbers";

const DropdownContent = ({ data, selectedItems }) => {
  const items = [
    {
      title: "Edit",
      icon: <FiEdit className="stroke-current text-xl" />,
      isEnabled: selectedItems.length === 1,
      link:
        selectedItems.length === 1
          ? "/master/edit/training/" + data[selectedItems[0]].id
          : "#",
    },
    {
      title: "Delete",
      icon: <FiTrash className="stroke-current text-xl" />,
      isEnabled: selectedItems.length >= 1,
      type: "modal",
      action: "delete",
    },
  ];

  return (
    <>
      <div>
        {items.map((item, i) => {
          return <DropdownItem key={i} {...item} />;
        })}
      </div>
    </>
  );
};

const Simple = ({ data, setSelectedItems }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Group",
        accessor: "group",
      },
      {
        Header: "Instruction",
        accessor: "instruction",
      },
    ],
    []
  );
  //const data = React.useMemo(() => countries, [])

  return (
    <Datatable
      columns={columns}
      data={data}
      setSelectedData={(items) => setSelectedItems(items)}
    />
  );
};

const Index = () => {
  let location = useLocation()
  const data = React.useMemo(() => exercises, []);
  const [selectedItems, setSelectedItems] = useState([]);
  const pathName = location.pathname.split("/")
  const pageShowData = pathName[pathName.length - 1]
  const [libraryData, setLibraryData] = useState([])

  useEffect(() => {
    if(pageShowData) {
      const itemsToBeShown = [];
      exercises.map(exercise => {
        if(exercise.type.includes(pageShowData)) {
          itemsToBeShown.push(exercise)
        }
      })

      setLibraryData(itemsToBeShown)
    }
  }, [pageShowData])

  return (
    <>
      <SectionTitle title="Master Data" subtitle={pageShowData[0].toUpperCase() + pageShowData.substring(1)} />
      <Widget
        title={`Use the ${pageShowData} library to add demo videos for your clients`}
        description={
          <Dropdown
            title={
              <div className="flex items-center -ml-4">
                Action
                <FiChevronDown className="ml-2 stroke-current" />
              </div>
            }
            toggleBgColor="bg-white"
            textColor="text-black"
            dropdownToggleClass="btn btn-default btn-outlined btn-outlined-indigo"
          >
            <DropdownContent data={data} selectedItems={selectedItems} />
          </Dropdown>
        }
        right={
          <div>
            <Link to={"/master/add/exercise"}>
              <button
                type="button"
                className="rounded btn p-2 bg-aktivin-blue text-white"
              >
                {`Add ${pageShowData}`}
              </button>
            </Link>
          </div>
        }
      >
        <Simple data={libraryData} setSelectedItems={setSelectedItems} />
      </Widget>
    </>
  );
};

export default Index;
