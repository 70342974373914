import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import SectionTitle from "../../components/section-title";
import Datatable from "../../components/datatable";
import merchants from "../../json/master/merchants.json";
import Widget from "../../components/widget";
import Dropdown from "../../components/dropdowns/dropdown-1";
import DropdownItem from "../../components/dropdowns/dropdownItem";
import Modal3 from "../../components/modals/modal-3";
import { FiEdit, FiTrash, FiMoreVertical, FiChevronDown } from "react-icons/fi";
import {
  fetchPhotographerReportDetail,
} from "../../actions";
import { formatMoney } from "accounting";

const Index = () => {
  const username = useSelector((state) => state.user.username);

  let history = useHistory();
  let dispatch = useDispatch();
  const { reportDate } = useParams();

  const reportDetailList = useSelector(
    (state) => state.gallery.reportDetailList
  );

  useEffect(() => {
    if(username && username.length > 0)
      dispatch(fetchPhotographerReportDetail(username, reportDate));
  }, [username]);

  return (
    <>
      <SectionTitle title="Galeri" subtitle="Laporan Pendapatan" />
      <Widget
        title={
          <span>Laporan Pendapatan Galeri Foto Detail - {reportDate}</span>
        }
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2">
          {reportDetailList &&
            reportDetailList.map((reportDetail, index) => {
              return (
                <div className="flex items-center border rounded border-1 shadow p-2">
                  <img
                    src={`https://storage.googleapis.com/${reportDetail.bucket_name}-thumbnail/${reportDetail.photo_name}`}
                    className="object-contain w-48"
                  />
                  <div className="ml-4">
                    <p className="mb-1">
                      Dibeli sebanyak{" "}
                      <span className="font-bold">{reportDetail.counter}</span>{" "}
                      kali
                    </p>
                    <p className="mb-1">
                      dengan harga satuan{" "}
                      <span className="font-bold">
                        {formatMoney(reportDetail.price, "IDR ", 0, ".", ",")}
                      </span>
                    </p>
                    <p className="text-lg mt-3">
                      TOTAL:{" "}
                      {formatMoney(
                        reportDetail.counter * reportDetail.price,
                        "IDR ",
                        0,
                        ".",
                        ","
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </Widget>
    </>
  );
};

export default Index;
