import React, { useState } from "react";
import SectionTitle from "../../components/section-title";
import Widget from "../../components/widget";
import logistics from "../../json/logistics"
import Videos from "../../components/dropzone/videos";
import Validation from "../../components/forms/validation";

const Index = () => {
  const [data1, onSubmit1] = useState(null);

  let items = [
    {
      label: "Metric Name",
      error: { required: "Please enter a valid name" },
      name: "name",
      type: "text",
      placeholder: "Enter metric name"
    },
    {
      label: "Description",
      // error: { required: "Please enter a valid name" },
      name: "description",
      type: "textarea",
      placeholder: "Add description for this metric (optional)"
    },
  ];

  return (
    <>
      <SectionTitle title="Master Data" subtitle="Metric" />

      <Widget
        title="Metric Registration"
        description={<span>Fill in below form to register new metric</span>}
      >
        <div className="flex flex-col lg:flex-row lg:flex-wrap w-full lg:space-x-4">
          <div className="w-full lg:w-1/2 lg:p-2">
            <Validation items={items} onSubmit={onSubmit1} alerts={false} />
          </div>
          <div className="w-full lg:w-1/2 lg:p-2">
            {data1 && <pre>{JSON.stringify(data1, null, 2)}</pre>}
          </div>
        </div>
      </Widget>
    </>
  );
};
export default Index;
