import React from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Link} from 'react-router-dom'

const Footer = () => {
  const {config} = useSelector(
    (state) => ({
      config: state.config
    }),
    shallowEqual
  )
  let {name, companyName} = {...config}
  return (
    <div>
      <div className="flex flex-row items-center justify-between w-full text-sm z-10">
      <div className="text-white">&copy; {companyName} {new Date().getFullYear()}</div>
      <div className="flex flex-row ml-auto space-x-3 font-bold">
        <a href="https://aktivin.id/privacy-policy" target="_blank">Privacy policy</a>
        <a href="https://aktivin.id/terms-of-service" target="_blank">Terms of service</a>
        <a href="https://wa.me/628992988123" target="_blank">Contact us</a>
      </div>
      
    </div>
      <p className="text-xs font-thin">Aktivin is a trademark of PT Aktif Motivasi Indonesia. Registered in the Directorate General of Intellectual Property of the Republic of Indonesia.</p>
    </div>
    
  )
}

export default Footer
