export const LOGIN_PROCESS_COMMENCED = "LOGIN_PROCESS_COMMENCED";
export const LOGIN_PROCESS_SUCCESS = "LOGIN_PROCESS_SUCCESS";
export const LOGIN_PROCESS_FAILURE = "LOGIN_PROCESS_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

export const FETCH_ACTIVITY_TYPE_COMMENCED = "FETCH_ACTIVITY_TYPE_COMMENCED";
export const FETCH_ACTIVITY_TYPE_SUCCESS = "FETCH_ACTIVITY_TYPE_SUCCESS";
export const FETCH_ACTIVITY_TYPE_FAILURE = "FETCH_ACTIVITY_TYPE_FAILURE";
export const FETCH_APPLICATION_NAME_COMMENCED =
  "FETCH_APPLICATION_NAME_COMMENCED";
export const FETCH_APPLICATION_NAME_SUCCESS = "FETCH_APPLICATION_NAME_SUCCESS";
export const FETCH_APPLICATION_NAME_FAILURE = "FETCH_APPLICATION_NAME_FAILURE";

export const FETCH_UNVERIFIED_ACTIVITIES_COMMENCED =
  "fetch_unverified_activities_commenced";
export const FETCH_UNVERIFIED_ACTIVITIES_SUCCESS =
  "fetch_unverified_activities_success";
export const FETCH_UNVERIFIED_ACTIVITIES_FAILURE =
  "fetch_unverified_activities_failure";

export const FETCH_RELATED_ACTIVITIES_COMMENCED =
  "FETCH_RELATED_ACTIVITIES_COMMENCED";
export const FETCH_RELATED_ACTIVITIES_SUCCESS =
  "FETCH_RELATED_ACTIVITIES_SUCCESS";
export const FETCH_RELATED_ACTIVITIES_FAILURE =
  "FETCH_RELATED_ACTIVITIES_FAILURE";
export const MANAGE_USER_ACTIVITIES_COMMENCED =
  "MANAGE_USER_ACTIVITIES_COMMENCED";
export const MANAGE_USER_ACTIVITIES_SUCCESS = "MANAGE_USER_ACTIVITIES_SUCCESS";
export const MANAGE_USER_ACTIVITIES_FAILURE = "MANAGE_USER_ACTIVITIES_FAILURE";

export const FETCH_EVENT_BUCKET_COMMENCED = "FETCH_EVENT_BUCKET_COMMENCED";
export const FETCH_EVENT_BUCKET_SUCCESS = "FETCH_EVENT_BUCKET_SUCCESS";
export const FETCH_EVENT_BUCKET_FAILURE = "FETCH_EVENT_BUCKET_FAILURE";
export const UPLOAD_IMAGE_TO_BUCKET_COMMENCED =
  "UPLOAD_IMAGE_TO_BUCKET_COMMENCED";
export const UPLOAD_IMAGE_TO_BUCKET_SUCCESS = "UPLOAD_IMAGE_TO_BUCKET_SUCCESS";
export const UPLOAD_IMAGE_TO_BUCKET_FAILURE = "UPLOAD_IMAGE_TO_BUCKET_FAILURE";
export const FETCH_IMAGE_LIST_COMMENCED = "FETCH_IMAGE_LIST_COMMENCED";
export const FETCH_IMAGE_LIST_SUCCESS = "FETCH_IMAGE_LIST_SUCCESS";
export const FETCH_IMAGE_LIST_FAILURE = "FETCH_IMAGE_LIST_FAILURE";
export const UPDATE_IMAGE_TAG_COMMENCED = "UPDATE_IMAGE_TAG_COMMENCED";
export const UPDATE_IMAGE_TAG_SUCCESS = "UPDATE_IMAGE_TAG_SUCCESS";
export const UPDATE_IMAGE_TAG_FAILURE = "UPDATE_IMAGE_TAG_FAILURE";
export const DELETE_FILE_FROM_BUCKET_COMMENCED =
  "DELETE_FILE_FROM_BUCKET_COMMENCED";
export const DELETE_FILE_FROM_BUCKET_SUCCESS =
  "DELETE_FILE_FROM_BUCKET_SUCCESS";
export const DELETE_FILE_FROM_BUCKET_FAILURE =
  "DELETE_FILE_FROM_BUCKET_FAILURE";
export const FETCH_PHOTOGRAPHER_REPORT_COMMENCED =
  "FETCH_PHOTOGRAPHER_REPORT_COMMENCED";
export const FETCH_PHOTOGRAPHER_REPORT_SUCCESS =
  "FETCH_PHOTOGRAPHER_REPORT_SUCCESS";
export const FETCH_PHOTOGRAPHER_REPORT_FAILURE =
  "FETCH_PHOTOGRAPHER_REPORT_FAILURE";
export const FETCH_PHOTOGRAPHER_REPORT_DETAIL_COMMENCED =
  "FETCH_PHOTOGRAPHER_REPORT_DETAIL_COMMENCED";
export const FETCH_PHOTOGRAPHER_REPORT_DETAIL_SUCCESS =
  "FETCH_PHOTOGRAPHER_REPORT_DETAIL_COMMENCED";
export const FETCH_PHOTOGRAPHER_REPORT_DETAIL_FAILURE =
  "FETCH_PHOTOGRAPHER_REPORT_DETAIL_FAILURE";

export const FETCH_FOLDERS_COMMENCED = "FETCH_FOLDERS_COMMENCED";
export const FETCH_FOLDERS_SUCCESS = "FETCH_FOLDERS_SUCCESS";
export const FETCH_FOLDERS_FAILURE = "FETCH_FOLDERS_FAILURE";
export const ADD_FOLDER_DATA = "ADD_FOLDER_DATA";
export const DELETE_FOLDER_DATA = "DELETE_FOLDER_DATA";
export const UPDATE_FOLDER_DATA = "UPDATE_FOLDER_DATA";
export const RESET_IMAGE_LIST = "RESET_IMAGE_LIST"
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS"
export const REMOVE_FROM_UPLOADED_LIST_BY_INDEX = "REMOVE_FROM_UPLOADED_LIST_BY_INDEX"
export const RESET_UPLOADED_LIST = "RESET_UPLOADED_LIST"