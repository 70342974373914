import {combineReducers} from 'redux'
import activity from './activity'
import config from './config'
import constants from './constants'
import gallery from './gallery'
import leftSidebar from './left-sidebar'
import palettes from './palettes'
import navigation from './navigation'
import user from './user'

const rootReducer = combineReducers({
  activity,
  navigation,
  config,
  constants,
  gallery,
  leftSidebar,
  palettes,
  user
})

export default rootReducer
