import React from 'react'
import {FiBox} from 'react-icons/fi'
import {useSelector, shallowEqual} from 'react-redux'
import {Link} from 'react-router-dom'

const Logo = () => {
  const {config} = useSelector(
    state => ({
      config: state.config
    }),
    shallowEqual
  )
  const {name} = {...config}
  return (
    <div className="uppercase font-extrabold text-base tracking-wider flex flex-row items-center justify-start w-full whitespace-no-wrap text-aktivin-blue">
      <Link
        to="/"
        className="flex flex-row items-center justify-start space-x-2">
        {/* <FiBox size={28} className="stroke-current" /> */}
        <img src={require("../../images/aktivin-logo.png")} width="40" />
        <span>{name}</span>
      </Link>
    </div>
  )
}

export default Logo
