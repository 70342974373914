import {
  FETCH_UNVERIFIED_ACTIVITIES_COMMENCED,
  FETCH_UNVERIFIED_ACTIVITIES_SUCCESS,
  FETCH_UNVERIFIED_ACTIVITIES_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  activities: [],
  loadingActivities: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_UNVERIFIED_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        loadingActivities: false,
      };
    case FETCH_UNVERIFIED_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: [],
        loadingActivities: false,
      };
    case FETCH_UNVERIFIED_ACTIVITIES_COMMENCED:
      return {
        ...state,
        activities: [],
        loadingActivities: true,
      };
    default:
      return state;
  }
};
