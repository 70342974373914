import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";

import Layouts from "./layouts";
import Routes from "./Routes";
import "./css/tailwind.css";
import "./css/main.css";
import "./css/animate.css";
import "./css/_components.css";
import "./firebase"

//reducers
import rootReducer from "./reducers";
import { setUser } from "./actions";

const store = createStore(rootReducer, {}, applyMiddleware(ReduxThunk));

const Wrapper = ({ children }) => {
  return <Layouts>{children}</Layouts>;
};

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Wrapper>
          <Routes />
        </Wrapper>
      </Router>
    </Provider>
  );
};
export default App;
