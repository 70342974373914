import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../../components/section-title";
import Widget from "../../components/widget";
import { Select } from "../../components/forms/selects";
import Previews from "../../components/dropzone/previews";
import { CircularBadge } from "../../components/badges";
import { fetchEventListAndBuckets, fetchFolderList, uploadImageToBucket, removeItemFromList, resetUploadImageList } from "../../actions";
import { BiHourglass, BiCheckCircle, BiXCircle, BiRevision } from "react-icons/bi";

export default function Index() {
  const dispatch = useDispatch();
  const [currentEvent, setCurrentEvent] = useState("");
  const [currentFolder, setCurrentFolder] = useState("");
  const username = useSelector((state) => state.user.username);
  // const username = "admin";
  const eventList = useSelector((state) => state.gallery.eventList);
  const folderList = useSelector((state) => state.gallery.folderList);
  const uploadedList = useSelector((state) => state.gallery.uploadedList);
  const [availableFolders, setAvailableFolders] = useState([]);
  const [folderIndex, setFolderIndex] = useState(-1);


  useEffect(() => {
    dispatch(fetchEventListAndBuckets());
  }, []);

  useEffect(() => {
    if (username && username.length > 0)
      dispatch(fetchFolderList(username));
  }, [username]);

  useEffect(() => {
    const availableList = currentEvent && currentEvent !== "" ? folderList.filter(
      (x) =>
        x.galleryId === eventList.find((x) => x.bucketName === currentEvent).id
    ) : [];

    if (currentEvent) {
      setAvailableFolders(availableList);
      if (currentFolder) {
        setCurrentFolder(availableList[0]);
      }
    }
  }, [currentEvent]);

  useEffect(() => {
    if (currentFolder && currentFolder.length > 0) {
      setFolderIndex(availableFolders.findIndex(x => x.id === currentFolder))
    }
  }, [currentFolder])

  return (
    <>
      <SectionTitle title="Galeri" subtitle="Galeri Foto" />
      <Widget
        title="Unggah Foto ke Galeri"
        description={
          <div>
            <div className="w-full mt-4">
              <Select
                label="Nama Event"
                items={[
                  {
                    eventName: "Pilih nama event di sini...",
                    bucketName: "",
                  },
                  ...eventList,
                ]}
                labelAccessor="eventName"
                valueAccessor="bucketName"
                message={
                  currentEvent == "" || currentEvent.bucketName == ""
                    ? "Mohon pilih event terlebih dahulu sebelum mengunggah foto"
                    : ""
                }
                onSelectAction={(e) => setCurrentEvent(e.target.value)}
              />
            </div>

            {currentEvent && (
              <div className="w-full mt-4">
                <Select
                  label="Nama Folder"
                  items={[
                    {
                      name: "Pilih nama folder di sini...",
                      id: "",
                    },
                    ...availableFolders,
                  ]}
                  labelAccessor="name"
                  valueAccessor="id"
                  message={
                    currentFolder && (currentFolder == "" || currentFolder.id == "")
                      ? "Mohon pilih folder terlebih dahulu sebelum mengunggah foto"
                      : ""
                  }
                  onSelectAction={(e) => setCurrentFolder(e.target.value)}
                />
              </div>
            )}
          </div>
        }
      >
        {currentEvent && currentEvent.bucketName != "" && currentFolder && (
          <Previews bucketName={currentEvent} tags={folderIndex > -1 ? availableFolders[folderIndex].tag : []} folderId={currentFolder} />
        )}

        <div className="grid grid-cols-2 gap-3">
          <button className="bg-blue-500 rounded py-2 px-6 text-white font-bold uppercase mt-6" onClick={(e) => {
            e.preventDefault()
            dispatch(resetUploadImageList())
          }}>
            Reset List Foto Diunggah
          </button>

          <button onClick={(e) => {
            e.preventDefault()
            if(uploadedList.filter(x => x.inProgress).length > 0) {
              alert("Masih ada foto yang sedang diproses. Mohon menunggu hingga semua foto telah diproses.")
            } else if(uploadedList.filter(x => x.error).length > 0) {
              const listErrorImages = uploadedList.filter(x => x.error);
              dispatch(resetUploadImageList())
              listErrorImages.map((x) => {
                dispatch(
                  uploadImageToBucket(
                    x.file,
                    currentEvent,
                    username,
                    folderIndex > -1 ? availableFolders[folderIndex].tag : [],
                    currentFolder
                  )
                );
              })
            }
          }} className={`${uploadedList && uploadedList.filter(x => x.error).length <= 0 ? "bg-grey-300" : "bg-blue-500"} rounded py-2 px-6 text-white font-bold uppercase mt-6`}>
            Ulangi Pengunggahan Foto yang Error
          </button>
        </div>
        {}
        {uploadedList &&
          uploadedList.map((file, i) => {
            return (
            <div className="flex items-center mt-4" key={i}>
              <div className="flex flex-1 items-center">
                <CircularBadge size="sm" color="bg-indigo-500 text-white mr-2">
                  {i + 1}
                </CircularBadge>
                <div>
                  {file && file.fileUrl ? (
                    <a
                      href={file.fileUrl}
                      target="_blank"
                      className="text-blue-500 font-lg underline"
                    >
                      {file.fileUrl}
                    </a>
                  ) : (
                    <div className="text-gray-800 font-lg">{file.fileName}</div>
                  )}
                  {file.error ? (
                    <div className="text-red-500">
                      {file.error && file.errorMsg}
                    </div>
                  ) : null}
                </div>
              </div>
              {file.inProgress ? (
                <BiHourglass size="20" className="text-gray-500" />
              ) : file.error ? (
                <button onClick={(e) => {
                  dispatch(removeItemFromList(i))
                  dispatch(
                    uploadImageToBucket(
                      file.file,
                      currentEvent,
                      username,
                      folderIndex > -1 ? availableFolders[folderIndex].tag : [],
                      currentFolder
                    )
                  );
                  e.preventDefault()
                }}>
                  <BiRevision size="20" className="text-red-500" />
                </button>
              ) : !file.inProgress && !file.error ? (
                <BiCheckCircle size="20" className="text-green-500" />
              ) : null}
            </div>
          )})}
      </Widget>
    </>
  );
}
