import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Modal2 from "../../components/modals/modal-2";
import { FiCheckCircle, FiTrash, FiXCircle } from "react-icons/fi";

function DropdownItem({
  title,
  icon,
  isEnabled,
  link,
  type,
  action,
  onClickAction,
}) {
  if (isEnabled && link) {
    return (
      <Link
        className={`${isEnabled ? "" : "text-grey-200"}`}
        to={isEnabled ? link : "#"}
      >
        <div className="flex items-center py-2 dropdown-item px-3">
          {icon}
          <span className={`text-xs ml-3`}>{title}</span>
        </div>
      </Link>
    );
  } else if (!isEnabled && link) {
    return (
      <div className="flex items-center py-2 dropdown-item px-3 text-grey-200">
        {icon}
        <span className={`text-xs ml-3`}>{title}</span>
      </div>
    );
  } else {
    if (type === "modal") {
      if (action === "delete" || action === "reject") {
        return (
          <Modal2
            icon={
              <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                <FiXCircle size={18} className="stroke-current text-red-500" />
              </span>
            }
            body={
              <div className="text-sm text-grey-500">
                Are you sure you want to {action} this data?
              </div>
            }
            buttonTitle="Confirm"
            outerButtonTitle={
              <div
                className={`flex items-center py-2 dropdown-item px-3 font-bold ${
                  !isEnabled ? "text-grey-200" : ""
                }`}
              >
                {icon}
                <span className={`text-xs ml-3`}>{title}</span>
              </div>
            }
            outerButtonClassName="w-full"
            onClickAction={onClickAction}
          />
        );
      } else if (action === "approve") {
        return (
          <Modal2
            icon={
              <span className="h-10 w-10 bg-green-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                <FiCheckCircle
                  size={18}
                  className="stroke-current text-green-500"
                />
              </span>
            }
            body={
              <div className="text-sm text-grey-500">
                Are you sure you want to {action} this data?
              </div>
            }
            buttonTitle="Confirm"
            outerButtonTitle={
              <div
                className={`flex items-center py-2 dropdown-item px-3 font-bold ${
                  !isEnabled ? "text-grey-200" : ""
                }`}
              >
                {icon}
                <span className={`text-xs ml-3`}>{title}</span>
              </div>
            }
            outerButtonClassName="w-full"
            onClickAction={onClickAction}
          />
        );
      }
      return null;
    }
    return null;
  }
}

export default DropdownItem;
