import {
  FETCH_EVENT_BUCKET_COMMENCED,
  FETCH_EVENT_BUCKET_SUCCESS,
  FETCH_EVENT_BUCKET_FAILURE,
  UPLOAD_IMAGE_TO_BUCKET_COMMENCED,
  UPLOAD_IMAGE_TO_BUCKET_SUCCESS,
  UPLOAD_IMAGE_TO_BUCKET_FAILURE,
  UPDATE_IMAGE_TAG_COMMENCED,
  UPDATE_IMAGE_TAG_SUCCESS,
  UPDATE_IMAGE_TAG_FAILURE,
  FETCH_IMAGE_LIST_COMMENCED,
  FETCH_IMAGE_LIST_SUCCESS,
  FETCH_IMAGE_LIST_FAILURE,
  FETCH_PHOTOGRAPHER_REPORT_COMMENCED,
  FETCH_PHOTOGRAPHER_REPORT_SUCCESS,
  FETCH_PHOTOGRAPHER_REPORT_FAILURE,
  FETCH_FOLDERS_COMMENCED,
  FETCH_FOLDERS_SUCCESS,
  FETCH_FOLDERS_FAILURE,
  UPDATE_FOLDER_DATA,
  FETCH_PHOTOGRAPHER_REPORT_DETAIL_COMMENCED,
  FETCH_PHOTOGRAPHER_REPORT_DETAIL_SUCCESS,
  FETCH_PHOTOGRAPHER_REPORT_DETAIL_FAILURE,
  ADD_FOLDER_DATA,
  DELETE_FOLDER_DATA,
  RESET_IMAGE_LIST,
  RESET_UPLOADED_LIST,
  DELETE_IMAGE_SUCCESS,
  REMOVE_FROM_UPLOADED_LIST_BY_INDEX
} from "../actions/types";

const INITIAL_STATE = {
  eventList: [],
  folderList: [],
  loadingEvents: false,
  loadingFolders: false,
  uploadedList: [],
  imageList: [],
  reportList: [],
  reportDetailList: [],
  updateTagSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_EVENT_BUCKET_SUCCESS:
      return {
        ...state,
        eventList: action.payload,
        loadingEvents: false,
      };
    case FETCH_EVENT_BUCKET_FAILURE:
      return {
        ...state,
        eventList: [],
        loadingEvents: false,
      };
    case FETCH_EVENT_BUCKET_COMMENCED:
      return {
        ...state,
        eventList: [],
        loadingEvents: true,
      };
    case FETCH_FOLDERS_SUCCESS:
      return {
        ...state,
        folderList: action.payload,
        loadingFolders: false,
      };
    case FETCH_FOLDERS_FAILURE:
      return {
        ...state,
        folderList: [],
        loadingFolders: false,
      };
    case FETCH_FOLDERS_COMMENCED:
      return {
        ...state,
        folderList: [],
        loadingFolders: true,
      };
    case UPLOAD_IMAGE_TO_BUCKET_COMMENCED:
      const newFilename = action.payload.name.replaceAll(" ", "_");
      return {
        ...state,
        uploadedList: [
          ...state.uploadedList,
          {
            file: action.payload,
            fileName: newFilename,
            inProgress: true,
            error: false,
            fileUrl: "",
          },
        ],
      };
    case UPLOAD_IMAGE_TO_BUCKET_SUCCESS:
      var originalFileName = action.payload.originalName;
      
      var foundIndex = state.uploadedList.findIndex(
        (x) => x.fileName == originalFileName.replaceAll(" ", "_") && x.inProgress === true
      );
      var newUploadedList = [...state.uploadedList];
      newUploadedList[foundIndex].inProgress = false;
      newUploadedList[foundIndex].fileUrl = action.payload.url;

      return {
        ...state,
        uploadedList: newUploadedList,
      };
    case UPLOAD_IMAGE_TO_BUCKET_FAILURE:
      var originalFileName =
        action.payload && action.payload.file
          ? action.payload.file.name.replaceAll(" ", "_")
          : "";
      var foundIndex = state.uploadedList.findIndex(
        (x) => x.fileName == originalFileName && x.inProgress === true
      );
      var newUploadedList = [...state.uploadedList];
      if (foundIndex > -1) {
        newUploadedList[foundIndex].inProgress = false;
        newUploadedList[foundIndex].error = true;
        newUploadedList[foundIndex].errorMsg =
          action.payload.errorMsg.toString();
      }

      return {
        ...state,
        uploadedList: newUploadedList,
      };
    case FETCH_IMAGE_LIST_SUCCESS:
      return {
        ...state,
        imageList: [...state.imageList, ...action.payload],
      };
    case RESET_IMAGE_LIST:
      return {
        ...state,
        imageList: [],
      };
    case UPDATE_IMAGE_TAG_SUCCESS:
      const newImageList = [...state.imageList];
      const foundIdx = newImageList.findIndex(x => x.bucket === action.payload.bucketName && x.name === action.payload.fileName)
      newImageList[foundIdx].tag = action.payload.newTag
      return {
        ...state,
        updateTagSuccess: true,
        imageList: newImageList
      };
    case UPDATE_IMAGE_TAG_FAILURE:
      return {
        ...state,
        updateTagSuccess: false,
      };
    case ADD_FOLDER_DATA:
      return {
        ...state,
        folderList: [...state.folderList, action.payload],
      };
    case UPDATE_FOLDER_DATA:
      const newFolderList = [...state.folderList];
      const newIndex = newFolderList.findIndex(
        (x) => x.id === action.payload.id
      );
      newFolderList[newIndex] = action.payload;
      return {
        ...state,
        folderList: newFolderList,
      };
    case DELETE_FOLDER_DATA:
      const newFolderLists = [...state.folderList];
      const foundedIndex = newFolderLists.findIndex(
        (x) => x.id === action.payload
      );
      newFolderLists.splice(foundedIndex, 1);
      return {
        ...state,
        folderList: newFolderLists,
      };
    case FETCH_PHOTOGRAPHER_REPORT_SUCCESS:
      return {
        ...state,
        reportList: action.payload,
      };
    case FETCH_PHOTOGRAPHER_REPORT_DETAIL_SUCCESS:
      return {
        ...state,
        reportDetailList: action.payload,
      };
    case DELETE_IMAGE_SUCCESS:
      const imageListNew = [...state.imageList]
      action.payload.map((deleteItem) => {
        const foundIndex = imageListNew.findIndex(x => x.name === deleteItem.fileName && x.bucket === deleteItem.bucketName)
        imageListNew.splice(foundIndex, 1)
      })

      return {
        ...state,
        imageList: imageListNew,
      }
    case REMOVE_FROM_UPLOADED_LIST_BY_INDEX:
      var newUploadedList = [...state.uploadedList]
      newUploadedList.splice(action.payload, 1)
      return {
        ...state,
        uploadedList: newUploadedList
      }
    case RESET_UPLOADED_LIST: {
      return {
        ...state,
        uploadedList: []
      }
    }
    default:
      return state;
  }
};
