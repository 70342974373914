import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import SectionTitle from "../../components/section-title";
import Datatable from "../../components/datatable";
import merchants from "../../json/master/merchants.json";
import Widget from "../../components/widget";
import Dropdown from "../../components/dropdowns/dropdown-1";
import DropdownItem from "../../components/dropdowns/dropdownItem";
import Modal3 from "../../components/modals/modal-3";
import { FiEdit, FiTrash, FiMoreVertical, FiChevronDown } from "react-icons/fi";
import {
  fetchPhotographerReport,
} from "../../actions";

const Index = () => {
  const username = useSelector((state) => state.user.username);

  let history = useHistory();
  let dispatch = useDispatch();

  const fields = [
    { key: "purchase_date", name: "Tanggal Pembelian" },
    { name: "Jumlah Pembelian", key: "transaction_no" },
    { name: "Omzet", key: "gross_income" },
    { name: "Pendapatan Bersih", key: "net_income" },
  ];
  const reportList = useSelector((state) => state.gallery.reportList);

  useEffect(() => {
    if(username && username.length > 0) 
      dispatch(fetchPhotographerReport(username));
  }, [username]);

  return (
    <>
      <SectionTitle title="Galeri" subtitle="Laporan Pendapatan" />
      <Widget title={<span>Laporan Pendapatan Galeri Foto</span>}>
        <table className="table">
          <thead>
            <tr>
              {fields.map((field, i) => (
                <th key={i}>{field.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {reportList.map((dailyReport, i) => (
              <tr key={i}>
                {fields.map((field, j) => {
                  if (j === 0) {
                    return (
                      <td key={j} className="underline text-blue-600">
                        <Link
                          to={`/gallery/report-detail/${
                            dailyReport[field.key]
                          }`}
                        >
                          {dailyReport[field.key]}
                        </Link>
                      </td>
                    );
                  }
                  return <td key={j}>{dailyReport[field.key]}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Widget>
    </>
  );
};

export default Index;
