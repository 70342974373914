import React, { useState } from "react";
import SectionTitle from "../../components/section-title";
import Widget from "../../components/widget";
import logistics from "../../json/logistics"
import Videos from "../../components/dropzone/videos";
import Validation from "../../components/forms/validation";

const Index = () => {
  const [data1, onSubmit1] = useState(null);

  // const currentItem = listItems.find(
  //   (x) => x.bucket === bucketName && x.name === fileName
  // );

  // const updateSuccess = useSelector((state) => state.gallery.updateTagSuccess);
  const [groups, setGroups] = useState(
    // currentItem && currentItem.group.length > 0 ? currentItem.tag.split(",") : []
    []
  );
  const [uploadFileFlag, setUploadFileFlag] = useState(false)
  const [uploadedFileUrl, setUploadedFileUrl] = useState("")
  const [newGroup, setNewGroup] = useState("");

  let items = [
    {
      label: "Exercise Name",
      error: { required: "Please enter a valid name" },
      name: "name",
      type: "text",
      placeholder: "Enter exercise name"
    },
    {
      label: "Publicly Available",
      type: "checkbox",
      name: "isPublic",
      options: [
        {label: "Yes", value: 1 },
      ]
    },
    {
      label: "Exercise Type",
      error: { required: "Please choose at least one type" },
      type: "checkbox",
      name: "type",
      options: [
        {label: "Warmup", value: "warmup"},
        {label: "Exercise", value: "exercise"},
        {label: "Cooldown", value: "cooldown"},
      ]
    },
    {
      label: "Exercise Group",
      name: "group",
      type: "tags",
      value: groups,
      textValue: newGroup,
      placeholder: "Enter exercise group and press Enter to add the group",
      onEnterPress: () => {
        var newGroups = [...groups, newGroup];
        setGroups(newGroups);
        setNewGroup("");
      },
      onChangeText: (newText) => {
        setNewGroup(newText);
      },
      onTagDelete: (index) => {
        var newGroups = [...groups];
        newGroups.splice(index, 1);
        setGroups(newGroups);
      },
    },
    {
      label: "Link to video",
      // error: {
      //   required:
      //     "Please enter link to video or upload your video below.",
      // },
      name: "videoUrl",
      type: "text",
      placeholder: "Enter your video URL",
      hidden: uploadFileFlag
    },
    {
      label: "Instruction",
      // error: { required: "Please enter a valid name" },
      name: "instruction",
      type: "textarea",
      placeholder: "Add instruction for this video (optional)"
    },
  ];

  return (
    <>
      <SectionTitle title="Master Data" subtitle="Exercise" />

      <Widget
        title="Exercise Registration"
        description={<span>Fill in below form to register new exercise</span>}
      >
        <div className="flex flex-col lg:flex-row lg:flex-wrap w-full lg:space-x-4">
        <div className="w-full lg:w-1/2 lg:p-2 mb-4">
            <input
              type="checkbox"
              label={"Upload new video?"}
              value={uploadFileFlag}
              name={'newVideoFlag'}
              className={`form-checkbox h-4 w-4 mr-2`}
              onClick={(e) => {
                setUploadFileFlag(e.target.checked)
              }}
            />
            <label>Upload video?</label>
          </div>

          {uploadFileFlag ? (
            <div className="mb-4">
              <Videos />
            </div>
          ) : null}

          <div className="w-full lg:w-1/2 lg:p-2">
            <Validation items={items} onSubmit={(data) => {
              let newData = {...data, videoUrl: uploadFileFlag ? uploadedFileUrl : ""  }
              onSubmit1(newData)
            }} alerts={false} />
          </div>
          <div className="w-full lg:w-1/2 lg:p-2">
            {data1 && <pre>{JSON.stringify(data1, null, 2)}</pre>}
          </div>
        </div>
      </Widget>
    </>
  );
};
export default Index;
