import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import SectionTitle from "../../components/section-title";
import Datatable from "../../components/datatable";
import merchants from "../../json/master/merchants.json";
import Widget from "../../components/widget";
import Dropdown from "../../components/dropdowns/dropdown-1";
import DropdownItem from "../../components/dropdowns/dropdownItem";
import Modal3 from "../../components/modals/modal-3";
import { FiEdit, FiTrash, FiMoreVertical, FiChevronDown } from "react-icons/fi";
import {
  deleteFileFromBucket,
  fetchImageList,
  fetchFolderList,
} from "../../actions";

const DropdownContent = ({ dispatch, data, selectedItems, username }) => {
  const items = [
    {
      title: "Delete",
      icon: <FiTrash className="stroke-current text-xl" />,
      isEnabled: selectedItems.length >= 1,
      type: "modal",
      action: "delete",
      onClickAction: () => {
        const toBeDeletedItems = [];
        selectedItems.map((index) =>
          toBeDeletedItems.push({
            bucketName: data[index].bucket,
            fileName: data[index].name,
          })
        );
        dispatch(deleteFileFromBucket(toBeDeletedItems, username));
      },
    },
  ];

  return (
    <>
      <div>
        {items.map((item, i) => {
          return <DropdownItem key={i} {...item} />;
        })}
      </div>
    </>
  );
};

const routeToEditForm = (selectedItem, history) => {
  const { bucket, name } = selectedItem;
  history.push(`/gallery/edit/${bucket}/${name}`);
};

const Simple = ({ data, setSelectedItems, history, folderList }) => {
  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Folder Name",
      //   accessor: "folder",
      //   Cell: (props) => (
      //     <button
      //       type="button"
      //       onClick={(e) => {
      //         e.preventDefault();
      //         routeToEditForm(props.data[props.row.index], history);
      //       }}
      //       className="text-blue-800 underline"
      //     >
      //       {props.value.substring(0, 100)}
      //       {props.value.length > 100 ? "..." : ""}
      //     </button>
      //   ),
      // },
      {
        Header: "Nama File",
        accessor: "name",
        Cell: (props) => (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              routeToEditForm(props.data[props.row.index], history);
            }}
            className="text-blue-800 underline"
          >
            {props.value.substring(0, 100)}
            {props.value.length > 100 ? "..." : ""}
          </button>
        ),
      },
      {
        Header: "Nama Folder",
        accessor: "folderName",
        Cell: (props) => {
          if (props.value && props.value.length > 0) {
            return (
              <span className="mr-2 bg-blue-500 text-white p-2 rounded">
                {props.value}
              </span>
            );
          } else return <div>{""}</div>;
        },
      },
      {
        Header: "URL Foto",
        accessor: "url",

        Cell: (props) => (
          <Modal3
            body={<img src={props.value} />}
            buttonTitle="Close"
            outerButtonTitle="Tampilkan foto"
            buttonClassName="btn btn-default btn-rounded bg-aktivin-blue text-white hover:bg-aktivin-bluehover w-full"
            outerButtonClassName="btn btn-default btn-rounded bg-aktivin-blue text-white hover:bg-aktivin-bluehover"
          />
        ),
      },
      {
        Header: "Tag Gambar",
        accessor: "tag",
        Cell: (props) =>
          props.value && props.value.length > 0 ? (
            props.value.map((tag) => {
              return (
                <span className="mr-2 bg-blue-500 text-white p-2 rounded">
                  {tag}
                </span>
              );
            })
          ) : null,
      },
    ],
    []
  );
  //const data = React.useMemo(() => countries, [])

  return (
    <Datatable
      columns={columns}
      data={data}
      setSelectedData={(items) => setSelectedItems(items)}
    />
  );
};

const Index = () => {
  let history = useHistory();
  let dispatch = useDispatch();
  let { folderName } = useParams();

  const [selectedItems, setSelectedItems] = useState([]);
  const listItems = useSelector((state) => state.gallery.imageList);
  const folderList = useSelector((state) => state.gallery.folderList);
  const [shownItemsList, setShownItemsList] = useState([]);
  const username = useSelector((state) => state.user.username);

  useEffect(() => {
    if (username && username.length > 0) {
      dispatch(fetchImageList(username));
      dispatch(fetchFolderList(username));
    }
  }, [username]);

  useEffect(() => {
    const newListItems = folderName
      ? [...listItems.filter((x) => x.folder === folderName)]
      : [...listItems];
    newListItems.forEach((item, index) => {
      const nameFolder = folderList.find(
        (x) => x.id.toString() === item.folder.toString()
      );
      if(nameFolder) {
        newListItems[index].folderName = nameFolder && nameFolder.name;
        newListItems[index].show = true
      }
      else
        newListItems[index].show = false
    });
    setShownItemsList(newListItems.filter(x => x.show));
  }, [listItems, folderList]);

  return (
    <>
      <SectionTitle title="Galeri" subtitle="Pengelolaan Galeri" />
      <Widget
        title={<span>Kelola Galeri Foto</span>}
        description={
          <Dropdown
            title={
              <div className="flex items-center -ml-4">
                Aksi
                <FiChevronDown className="ml-2 stroke-current" />
              </div>
            }
            toggleBgColor="bg-white"
            textColor="text-black"
            dropdownToggleClass="btn btn-default btn-outlined btn-outlined-indigo"
          >
            <DropdownContent
              dispatch={dispatch}
              data={listItems}
              selectedItems={selectedItems}
              username={username}
            />
          </Dropdown>
        }
        right={
          <div>
            <Link to={"/gallery/upload"}>
              <button
                type="button"
                className="rounded btn p-2 bg-aktivin-blue text-white"
              >
                Unggah Foto
              </button>
            </Link>
          </div>
        }
      >
        <Simple
          data={shownItemsList}
          setSelectedItems={setSelectedItems}
          history={history}
          folderList={folderList}
        />
      </Widget>
    </>
  );
};

export default Index;
