import React, {useState} from 'react'
import { useHistory } from "react-router-dom"
import Validation from '../forms/validation'
import Alert from '../alerts'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../../actions'
import { useEffect } from 'react'

const Login = ({message = null}) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const authError = useSelector((state) => state.user.authError);
  const isLoading = useSelector((state) => state.user.loadingAuth);
  const userName =  useSelector((state) => state.user);

  const [data, setData] = useState(null);

  useEffect(() => {
    if(userName && userName.username) {
      history.push("/dashboard")
    }
  }, [userName])

  let items = [
    {
      label: 'Email',
      error: {required: 'Please enter a valid email'},
      name: 'email',
      type: 'text',
      placeholder: 'Enter your email address',
      onChangeText: (newText) => setData({...data, email: newText})
    },
    {
      label: 'Password',
      error: {
        required: 'Password is required',
        minLength: {
          value: 8,
          message: 'Your password should have at least 8 characters'
        }
      },
      name: 'password',
      type: 'password',
      placeholder: 'Enter your password',
      onChangeText: (newText) => setData({...data, password: newText})
    },
  ]
  return (
    <>
      <div className="flex flex-col">
        {data && authError && (
          <div className="w-full mb-4">
            <Alert
              color="bg-transparent border-red-500 text-red-500"
              borderLeft
              raised>
              {authError}
            </Alert>
          </div>
        )}
        <Validation items={items} loading={isLoading} onSubmit={(data) => {
          dispatch(loginUser(data))
          // setData(data); 
          // history.push("/dashboard")
        }} 
        />
      </div>
    </>
  )
}

export default Login
