import React, { useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/section-title";
import Datatable from "../../components/datatable";
import metrics from "../../json/master/metrics.json";
import Widget from "../../components/widget";
import Modal3 from "../../components/modals/modal-3";
import Dropdown from "../../components/dropdowns/dropdown-1";
import DropdownItem from "../../components/dropdowns/dropdownItem";
import { FiEdit, FiTrash, FiChevronDown } from "react-icons/fi";

const DropdownContent = ({ data, selectedItems }) => {
  const items = [
    {
      title: "Edit",
      icon: <FiEdit className="stroke-current text-xl" />,
      isEnabled: selectedItems.length === 1,
      link:
        selectedItems.length === 1
          ? "/master/edit/metric/" + data[selectedItems[0]].id
          : "#",
    },
    {
      title: "Delete",
      icon: <FiTrash className="stroke-current text-xl" />,
      isEnabled: selectedItems.length >= 1,
      type: "modal",
      action: "delete",
    },
  ];

  return (
    <>
      <div>
        {items.map((item, i) => {
          return <DropdownItem key={i} {...item} />;
        })}
      </div>
    </>
  );
};

const Simple = ({ data, setSelectedItems }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "# of Assignments",
        accessor: "occurence",
      },
      {
        Header: "",
        accessor: "id",
        Cell: (props) => (
          <Modal3
            body={<img src={props.value} />}
            buttonTitle="Close"
            outerButtonTitle="Assign to client"
            buttonClassName="btn btn-default btn-rounded bg-aktivin-blue text-white hover:bg-aktivin-bluehover w-full"
            outerButtonClassName="btn btn-default btn-rounded bg-aktivin-blue text-white hover:bg-aktivin-bluehover"
          />
        ),
      }
    ],
    []
  );
  //const data = React.useMemo(() => countries, [])

  return (
    <Datatable
      columns={columns}
      data={data}
      setSelectedData={(items) => setSelectedItems(items)}
    />
  );
};

const Index = () => {
  const data = React.useMemo(() => metrics, []);
  const [selectedItems, setSelectedItems] = useState([]);
  
  return (
    <>
      <SectionTitle title="Master Data" subtitle={"Metric"} />
      <Widget
        title={`Create sets of metric to quickly assign to your clients`}
        description={
          <Dropdown
            title={
              <div className="flex items-center -ml-4">
                Action
                <FiChevronDown className="ml-2 stroke-current" />
              </div>
            }
            toggleBgColor="bg-white"
            textColor="text-black"
            dropdownToggleClass="btn btn-default btn-outlined btn-outlined-indigo"
          >
            <DropdownContent data={data} selectedItems={selectedItems} />
          </Dropdown>
        }
        right={
          <div>
            <Link to={"/master/add/metric"}>
              <button
                type="button"
                className="rounded btn p-2 bg-aktivin-blue text-white"
              >
                Add Metric
              </button>
            </Link>
          </div>
        }
      >
        <Simple data={data} setSelectedItems={setSelectedItems} />
      </Widget>
    </>
  );
};

export default Index;
