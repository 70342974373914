import {
  LOGIN_PROCESS_COMMENCED,
  LOGIN_PROCESS_SUCCESS,
  LOGIN_PROCESS_FAILURE,
  LOGOUT_USER,
} from "../actions/types";


const INITIAL_STATE = {
  name: '',
  email: '',
  username: null,
  location: '',
  company: '',
  description: '',
  img: '',
  color: '',
  country: '',
  role: '',
  authError: "",
  loadingAuth: false
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_PROCESS_COMMENCED:
      return {
        ...state,
        name: "",
        email: "",
        username: null,
        role: "",
        authError: "",
        loadingAuth: true
      }
    case LOGIN_PROCESS_SUCCESS:
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        username: action.payload.slug,
        role: action.payload.user_role,
        loadingAuth: false
      }
    case LOGIN_PROCESS_FAILURE:
      return {
        ...state,
        name: "",
        email: "",
        username: null,
        role: "",
        authError: action.payload,
        loadingAuth: false
      }
    case LOGOUT_USER:
      return {
        ...state,
        name: "",
        email: "",
        username: null,
        role: "",
      }
    default:
      return state
  }
}
