import React from 'react'

const ClientList = ({items}) => (
  <div className="w-full mb-4">
    {items.map((item, i) => (
      <div
        className="flex items-center justify-start p-2 space-x-4"
        key={i}>
        <div className="flex-shrink-0 w-8">
          <img
            src={item.imageUrl}
            alt="media"
            className="h-8 w-full shadow-lg rounded-full"
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="text-sm">{item.name}</div>
        </div>
      </div>
    ))}
  </div>
)

export default ClientList
