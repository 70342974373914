import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  FiFolder,
  FiMinus,
  FiMoreVertical,
  FiPlus,
  FiTrash,
  FiX,
} from "react-icons/fi";

import SectionTitle from "../../components/section-title";
import Widget from "../../components/widget";
import { Select } from "../../components/forms/selects";
import Previews from "../../components/dropzone/previews";
import { CircularBadge } from "../../components/badges";
import Dropdown from "../../components/dropdowns/dropdown-1";
import Modal1 from "../../components/modals/modal-1";
import Modal2 from "../../components/modals/modal-2";

import {
  fetchEventListAndBuckets,
  fetchFolderList,
  addFolder,
  deleteFolder,
  updateFolderName,
} from "../../actions";

export default function Index() {
  const dispatch = useDispatch();
  let history = useHistory();

  const [currentEvent, setCurrentEvent] = useState(null);
  const [newGalleryId, setNewGalleryId] = useState("");
  const [newFolderName, setNewFolderName] = useState("");
  const [newFolderTags, setNewFolderTags] = useState([]);
  const username = useSelector((state) => state.user.username);
  // const username = "admin";
  const eventList = useSelector((state) => state.gallery.eventList);
  const folderList = useSelector((state) => state.gallery.folderList);
  const [shownFolderList, setShownFolderList] = useState([]);

  useEffect(() => {
    dispatch(fetchEventListAndBuckets());
  }, []);

  useEffect(() => {
    if (username && username.length > 0) {
      dispatch(fetchFolderList(username));
    }
  }, [username]);

  useEffect(() => {
    const newFolderList =
      folderList &&
      folderList.filter((x) =>
        currentEvent ? x.galleryId === currentEvent.id : (x) => x.id.length > 0
      );
    setShownFolderList(newFolderList);
  }, [currentEvent, folderList]);

  return (
    <>
      <SectionTitle title="Folder" subtitle="Pengelolaan Folder Foto" />
      <Widget
        title="Daftar Folder Foto"
        description={
          <div className="pt-4">
            <Dropdown
              width="w-64"
              placement={`bottom-start`}
              title={currentEvent ? currentEvent.eventName : `Pilih Event`}
              dropdownToggleClass="btn btn-default btn-outlined btn-outlined-indigo"
            >
              <div className="flex flex-wrap text-center">
                <button
                  key={"null"}
                  className="w-full h-10 space-y-1 dropdown-item"
                  onClick={() => {
                    setCurrentEvent(null);
                  }}
                >
                  Semua Event
                </button>
                {eventList &&
                  eventList.map((item) => {
                    return (
                      <button
                        key={item.id}
                        className="w-full h-10 space-y-1 dropdown-item"
                        onClick={() => {
                          setCurrentEvent(item);
                        }}
                      >
                        {item.eventName}
                      </button>
                    );
                  })}
              </div>
            </Dropdown>
          </div>
        }
        right={
          <div>
            <Modal1
              title="Tambah folder"
              buttonTitle="Simpan Perubahan"
              closeButtonTitle="Keluar"
              buttonClassName="btn btn-default btn-rounded bg-red-500 hover:bg-red-600 text-white"
              outerButtonTitle="Tambah Folder"
              outerButtonClassName={`rounded btn p-2 bg-aktivin-blue text-white`}
              body={
                <div>
                  <p className="font-bold mb-2">Nama event</p>
                  <select
                    className="w-full bg-white p-2 border border-1"
                    onChange={(e) => {
                      setNewGalleryId(e.target.value);
                    }}
                  >
                    <option value={""}>Pilih nama event...</option>
                    {eventList.map((event) => (
                      <option key={event.id} value={event.id}>
                        {event.eventName}
                      </option>
                    ))}
                  </select>

                  <p className="font-bold mt-4 mb-2">Nama folder</p>
                  <input
                    type="text"
                    value={newFolderName}
                    className="border border-1 py-2 px-2 w-full"
                    onChange={(e) => setNewFolderName(e.target.value)}
                  />

                  <div className="flex items-end mt-4 mb-2">
                    <div>
                      <p className="font-bold flex-1">Tag Foto Otomatis</p>
                      <p className="text-xs">
                        Tag foto akan ditambahkan otomatis ke semua foto yang
                        diunggah ke folder
                      </p>
                    </div>
                    <button
                      className="p-2 bg-blue-500 text-white rounded ml-6"
                      onClick={(e) => {
                        const newTags = [...newFolderTags];
                        newTags.push("");
                        setNewFolderTags(newTags);
                      }}
                    >
                      <FiPlus />
                    </button>
                  </div>
                  {newFolderTags.map((tag, index) => (
                    <div className="flex mb-1">
                      <input
                        type="text flex-1"
                        value={tag}
                        className="border border-1 py-1 px-2 w-full"
                        onChange={(e) => {
                          const newTags = [...newFolderTags];
                          newTags[index] = e.target.value;
                          setNewFolderTags(newTags);
                        }}
                      />
                      <button
                        className="px-2 py-1 bg-red-500 text-white rounded ml-1"
                        onClick={(e) => {
                          const newTags = [...newFolderTags];
                          newTags.splice(index, 1);
                          setNewFolderTags(newTags);
                        }}
                      >
                        <FiMinus />
                      </button>
                    </div>
                  ))}
                </div>
              }
              openModalAction={() => {
                setNewFolderName("");
                setNewFolderTags([]);
                setNewGalleryId("");
              }}
              buttonAction={() =>
                dispatch(
                  addFolder(
                    {
                      name: newFolderName,
                      tag: newFolderTags,
                      galleryId: newGalleryId,
                    },
                    username
                  )
                )
              }
            />
          </div>
        }
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
          {shownFolderList.map((folder, i) => {
            const eventBucket = eventList.find(
              (x) => x.id === folder.galleryId
            );
            if (eventBucket)
              return (
                <div key={folder.id} className="relative w-64">
                  <div className="">
                    <button
                      onClick={() =>
                        history.push(`/gallery/preview/${eventBucket.bucketName + "-thumbnail"}/${folder.id}`)
                      }
                      className="rounded w-64 h-64 flex items-center justify-center"
                    >
                      <FiFolder className="h-48 w-48 text-blue-500" />
                    </button>

                    <p className="font-bold mt-1 text-center">{folder.name}</p>
                  </div>
                  <div className="absolute p-1 top-0 right-0">
                    <Dropdown
                      placement="bottom-start"
                      title={
                        <button className="btn btn-default">
                          <FiMoreVertical className="w-5 h-8 stroke-current hover:text-grey-400 absolute top-0" />
                        </button>
                      }
                      toggleBgColor="bg-white"
                      textColor="text-black"
                      nonButtonStyle
                    >
                      <Modal1
                        title="Kelola folder"
                        buttonTitle="Simpan Perubahan"
                        closeButtonTitle="Keluar"
                        buttonClassName="btn btn-default btn-rounded bg-red-500 hover:bg-red-600 text-white"
                        outerButtonTitle="Kelola folder"
                        outerButtonClassName={`w-full flex flex-col justify-center items-start h-10 space-y-1 dropdown-item pl-2`}
                        body={
                          <div>
                            <p className="font-bold mb-2">Nama event</p>
                            <select
                              className="w-full bg-white p-2 border border-1"
                              value={folder.galleryId}
                              onChange={(e) => {
                                setNewGalleryId(e.target.value);
                              }}
                            >
                              <option value={""}>Pilih nama event...</option>
                              {eventList.map((event) => (
                                <option key={event.id} value={event.id}>
                                  {event.eventName}
                                </option>
                              ))}
                            </select>

                            <p className="font-bold mt-4 mb-2">Nama folder</p>
                            <input
                              type="text"
                              value={newFolderName}
                              className="border border-1 py-2 px-2 w-full"
                              onChange={(e) => setNewFolderName(e.target.value)}
                            />

                            <div className="flex items-end mt-4 mb-2">
                              <div>
                                <p className="font-bold flex-1">
                                  Tag Foto Otomatis
                                </p>
                                <p className="text-xs">
                                  Tag foto akan ditambahkan otomatis ke semua
                                  foto yang diunggah ke folder
                                </p>
                              </div>
                              <button
                                className="p-2 bg-blue-500 text-white rounded ml-6"
                                onClick={(e) => {
                                  const newTags = [...newFolderTags];
                                  newTags.push("");
                                  setNewFolderTags(newTags);
                                }}
                              >
                                <FiPlus />
                              </button>
                            </div>
                            {newFolderTags.map((tag, index) => (
                              <div className="flex mb-1">
                                <input
                                  type="text flex-1"
                                  value={tag}
                                  className="border border-1 py-1 px-2 w-full"
                                  onChange={(e) => {
                                    const newTags = [...newFolderTags];
                                    newTags[index] = e.target.value;
                                    setNewFolderTags(newTags);
                                  }}
                                />
                                <button
                                  className="px-2 py-1 bg-red-500 text-white rounded ml-1"
                                  onClick={(e) => {
                                    const newTags = [...newFolderTags];
                                    newTags.splice(index, 1);
                                    setNewFolderTags(newTags);
                                  }}
                                >
                                  <FiMinus />
                                </button>
                              </div>
                            ))}
                          </div>
                        }
                        openModalAction={() => {
                          setNewFolderName(folder.name);
                          setNewFolderTags(folder.tag);
                          setNewGalleryId(folder.galleryId);
                        }}
                        buttonAction={() =>
                          dispatch(
                            updateFolderName(
                              {
                                id: folder.id,
                                name: newFolderName,
                                tag: newFolderTags,
                                galleryId: newGalleryId,
                              },
                              username
                            )
                          )
                        }
                      />
                      <Link to={`/gallery/manage/${folder.id}`}>
                        <button className="w-full flex flex-col justify-center items-start h-10 space-y-1 dropdown-item pl-2">
                          Kelola foto
                        </button>
                      </Link>
                      <Link to={`/gallery/preview/${eventBucket.bucketName + "-thumbnail"}/${folder.id}`}>
                        <button className="w-full flex flex-col justify-center items-start h-10 space-y-1 dropdown-item pl-2">
                          Preview folder
                        </button>
                      </Link>
                      <Modal2
                        icon={
                          <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                            <FiTrash
                              size={18}
                              className="stroke-current text-red-500"
                            />
                          </span>
                        }
                        body={
                          <div className="text-sm text-grey-500">
                            Apakah Anda yakin ingin menghapus folder{" "}
                            {folder.name}?
                          </div>
                        }
                        title="Hapus folder?"
                        buttonTitle="Hapus"
                        cancelButtonTitle="Batalkan"
                        buttonClassName="btn btn-default btn-rounded bg-red-500 hover:bg-red-600 text-white"
                        outerButtonTitle="Hapus"
                        outerButtonClassName={`w-full flex flex-col justify-center items-start h-10 space-y-1 dropdown-item pl-2`}
                        onClickAction={() => {
                          dispatch(deleteFolder(folder.id));
                        }}
                      />
                    </Dropdown>
                  </div>
                </div>
              );
          })}
        </div>
      </Widget>
    </>
  );
}
