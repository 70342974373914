import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  FiFolder,
  FiMinus,
  FiMoreVertical,
  FiPlus,
  FiTrash,
  FiX,
} from "react-icons/fi";

import SectionTitle from "../../components/section-title";
import Widget from "../../components/widget";
import { Select } from "../../components/forms/selects";
import Previews from "../../components/dropzone/previews";
import { CircularBadge } from "../../components/badges";
import Dropdown from "../../components/dropdowns/dropdown-1";
import Modal1 from "../../components/modals/modal-1";
import Modal2 from "../../components/modals/modal-2";

import {
  fetchEventListAndBuckets,
  fetchFolderList,
  fetchImageList,
  addFolder,
  deleteFolder,
  updateFolderName,
  deleteFileFromBucket,
  fetchImageListByFolder,
  resetImageList,
} from "../../actions";

export default function Index() {
  const dispatch = useDispatch();
  let history = useHistory();
  const { folderName, bucketName } = useParams();
  const photoPerPage = 480;

  const [currentFolder, setCurrentFolder] = useState({});
  const [listPhoto, setListPhoto] = useState([]);
  const [page, setPage] = useState(1);

  const username = useSelector((state) => state.user.username);
  const folderList = useSelector((state) => state.gallery.folderList);
  const imageList = useSelector((state) => state.gallery.imageList);

  useEffect(() => {
    dispatch(fetchEventListAndBuckets());
    // dispatch(resetImageList());
  }, []);

  useEffect(() => {
    if (username && username.length > 0) {
      dispatch(fetchFolderList(username));
      dispatch(fetchImageListByFolder(bucketName, folderName, page));
    }
  }, [username]);

  useEffect(() => {
    setCurrentFolder(folderList.find((x) => x.id === folderName));
  }, [folderList]);

  useEffect(() => {
    setListPhoto([...imageList.filter((x) => x.folder === folderName)]);
  }, [imageList]);

  return (
    <>
      <SectionTitle title="Folder" subtitle="Preview Folder" />
      <Widget
        title={`Preview Folder`}
        description={currentFolder && currentFolder.name}
        right={
          <button
            type="button"
            className="rounded btn p-2 bg-aktivin-blue text-white"
            onClick={() => history.goBack()}
          >
            Kembali
          </button>
        }
      >
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
            {listPhoto.slice(0, page * photoPerPage).map((photo, index) => {
              return (
                <div
                  key={index}
                  className={`shadow-sm rounded flex flex-col items-between`}
                >
                  {/* <Checkmark selected={isSelected} /> */}
                  <div className={`relative flex flex-col h-full`}>
                    <div className="flex flex-1 items-center">
                      <img className={`w-full`} src={photo.url} />
                    </div>
                    <p className="text-xs">{photo.name}</p>
                    <div className="mt-2">
                      <div className="flex flex-wrap mb-2">
                        {photo.tag &&
                          photo.tag.map((tagItem, index) => {
                            return (
                              <span
                                key={index}
                                className="bg-blue-500 p-2 mr-2 mb-2 rounded text-white flex items-center"
                              >
                                {tagItem}
                              </span>
                            );
                          })}
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <Link
                          to={`/gallery/edit/${photo.bucket}/${photo.name}`}
                        >
                          <button className="btn btn-default rounded bg-aktivin-blue hover:bg-aktivin-bluehover text-white w-full mt-2">
                            Edit Tag
                          </button>
                        </Link>
                        <Modal2
                          icon={
                            <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                              <FiTrash
                                size={18}
                                className="stroke-current text-red-500"
                              />
                            </span>
                          }
                          body={
                            <div className="text-sm text-grey-500">
                              Apakah Anda yakin ingin menghapus foto ini?
                            </div>
                          }
                          title="Hapus foto?"
                          buttonTitle="Hapus"
                          cancelButtonTitle="Batalkan"
                          buttonClassName="btn btn-default rounded bg-red-500 hover:bg-red-600 text-white"
                          outerButtonTitle="Hapus"
                          outerButtonClassName={`btn btn-default rounded bg-red-500 hover:bg-red-600 text-white w-full mt-2`}
                          onClickAction={() => {
                            dispatch(
                              deleteFileFromBucket(
                                [
                                  {
                                    fileName: photo.name,
                                    bucketName: photo.bucket,
                                  },
                                ],
                                username
                              )
                            );
                            dispatch(
                              deleteFileFromBucket(
                                [
                                  {
                                    fileName: photo.name,
                                    bucketName: photo.bucket.replace(
                                      "-thumbnail",
                                      ""
                                    ),
                                  },
                                ],
                                username
                              )
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              );
            })}
          </div>
          {listPhoto.length >= page * photoPerPage && (
            <button
              className="btn btn-default flex mt-16 mx-auto border border-1 mb-4"
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  fetchImageListByFolder(bucketName, folderName, page + 1)
                );
                setPage(page + 1);
              }}
            >
              Muat selanjutnya
            </button>
          )}
        </div>
      </Widget>
    </>
  );
}
