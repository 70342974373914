import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SectionTitle from "../../components/section-title";
import Datatable from "../../components/datatable";
import coaches from "../../json/coaches.json";
import Widget from "../../components/widget";
import Dropdown from "../../components/dropdowns/dropdown-1";
import DropdownItem from "../../components/dropdowns/dropdownItem";
import { FiEdit, FiTrash, FiChevronDown } from "react-icons/fi";

const DropdownContent = ({ data, selectedItems }) => {
    const items = [
        {
            title: "Edit",
            icon: <FiEdit className="stroke-current text-xl" />,
            isEnabled: selectedItems.length === 1,
            link:
                selectedItems.length === 1
                    ? "/master/edit/merchant/" + data[selectedItems[0]].id
                    : "#",
        },
        {
            title: "Delete",
            icon: <FiTrash className="stroke-current text-xl" />,
            isEnabled: selectedItems.length >= 1,
            type: "modal",
            action: "delete",
        },
    ];

    return (
        <>
            <div>
                {items.map((item, i) => {
                    return <DropdownItem key={i} {...item} />
                })}
            </div>
        </>
    );
};

const Simple = ({ data, setSelectedItems }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: (props) => (
                    <a className="text-blue-700 underline" href={"mailto:" + props.value}>{props.value}</a>
                ),
            },
            {
                Header: "",
                accessor: "imageUrl",
                Cell: (props) => (
                    <img
                        src={props.value}
                        alt="client-photo"
                        className="h-12 w-12 shadow-lg rounded-full"
                    />
                ),
            },
            {
                Header: "Coach Name",
                accessor: "name",
            }
        ],
    );
    //const data = React.useMemo(() => countries, [])

    return (
        <Datatable
            columns={columns}
            data={data}
            setSelectedData={(items) => setSelectedItems(items)}
        />
    );
};

const Index = () => {
    const data = React.useMemo(() => coaches, []);
    const [selectedItems, setSelectedItems] = useState([]);

    return (
        <>
            <SectionTitle title="Training Management" subtitle="Coaching Teams" />
            <Widget
                title={`Manage your list of coaches`}
                description={
                    <Dropdown
                        title={
                            <div className="flex items-center -ml-4">
                                Action
                                <FiChevronDown className="ml-2 stroke-current" />
                            </div>
                        }
                        toggleBgColor="bg-white"
                        textColor="text-black"
                        dropdownToggleClass="btn btn-default btn-outlined btn-outlined-indigo"
                    >
                        <DropdownContent data={data} selectedItems={selectedItems} />
                    </Dropdown>
                }
                right={
                    <div className="flex">
                        <Link
                            to={"/training/teams/add"}
                        >
                            <button type="button" className="rounded btn p-2 bg-aktivin-blue text-white">Add Team</button>
                        </Link>
                    </div>

                }
            >
                <Simple data={data} setSelectedItems={setSelectedItems} />
            </Widget>
        </>
    );
};

export default Index;
