import React from "react";

const Text = () => {
  return (
    <div className="leading-6 mb-4 text-base">
      <span className="text-blue-500 font-bold">Aktivin</span> is the first
      all-rounded sporting app in Indonesia to support virtual events,
      e-commerce, virtual coaching and donation in one roof.
      <span className="font-bold"> Our team</span> has handled more than 20
      events and 6+ clients.
    </div>
  );
};

export default Text;
