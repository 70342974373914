import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

import { uploadImageToBucket } from "../../actions";

const Previews = (props) => {
  const username = useSelector((state) => state.user.username);

  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          // Object.assign(file, {
          //   preview: URL.createObjectURL(file)
          // })
          dispatch(
            uploadImageToBucket(
              file,
              props.bucketName,
              username,
              props.tags,
              props.folderId
            )
          );
        })
      );
    },
  });

  // useEffect(
  //   () => () => {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     files.forEach((file) => {
  //       URL.revokeObjectURL(file.preview);
  //     });
  //   },
  //   [files]
  // );

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={`${
            isDragActive
              ? "bg-grey-100 dark:bg-grey-800"
              : "bg-grey-50 dark:bg-grey-700"
          } border-dashed border-4 border-grey-200 dark:border-grey-600 p-8 h-32 w-full flex items-center justify-center`}
        >
          <div className="font-bold text-base">
            Drag and drop foto yang ingin diunggah ke sini, atau klik untuk
            memilih foto
          </div>
        </div>
      </div>
    </>
  );
};

export default Previews;
