import {
  FETCH_ACTIVITY_TYPE_SUCCESS,
  FETCH_APPLICATION_NAME_SUCCESS,
} from "../actions/types";

export default function config(
  state = {
    activityTypes: [],
    applicationNames: [],
  },
  action
) {
  switch (action.type) {
    case FETCH_ACTIVITY_TYPE_SUCCESS:
      return {
        ...state,
        activityTypes: action.payload,
      };
    case FETCH_APPLICATION_NAME_SUCCESS:
      return {
        ...state,
        applicationNames: action.payload,
      };
    default:
      return state;
  }
}
