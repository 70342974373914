import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC9QMm09m_KTV4Av0rnhntijp2r2rd4KR0",
  authDomain: "aktivin-partner-photographer.firebaseapp.com",
  projectId: "aktivin-partner-photographer",
  storageBucket: "aktivin-partner-photographer.appspot.com",
  messagingSenderId: "238523533786",
  appId: "1:238523533786:web:3647c65f840b3e252458e3",
  measurementId: "G-002K6VRN1G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;