import axios from "axios";
import {
  LOGIN_PROCESS_COMMENCED,
  LOGIN_PROCESS_SUCCESS,
  LOGIN_PROCESS_FAILURE,
  LOGOUT_USER
} from "./types";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from "firebase/auth";

export const loginUser = ({ email, password }) => {
  // let url = `https://0ehxwdlx4a.execute-api.ap-southeast-1.amazonaws.com/prod/auth/login`;
  // let url = `https://0ehxwdlx4a.execute-api.ap-southeast-1.amazonaws.com/prod/auth/login`;
  let url = "https://aktivin-partner-photographer.et.r.appspot.com/user/login";


  const auth = getAuth();

  return (dispatch) => {
    dispatch({ type: LOGIN_PROCESS_COMMENCED });

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //Signed in
        const user = userCredential.user;

        if (user) {
          axios.post(url, { email }).then((success) => {
            if (success.data.user)
              loginUserSuccess(dispatch, success.data.user);
            else loginUserFailure(dispatch, "auth/user-not-found");
          })
        }
      })
      .catch((error) => {
        const errorCode = error.errorCode;
        const errorMessage = error.message;
        loginUserFailure(dispatch, errorMessage.replace("Firebase: ", ""));
      });
  };
};

const loginUserSuccess = (dispatch, list) => {
  localStorage.setItem('user', JSON.stringify(list))
  dispatch({ type: LOGIN_PROCESS_SUCCESS, payload: list });
};

const loginUserFailure = (dispatch, error) => {
  dispatch({ type: LOGIN_PROCESS_FAILURE, payload: error });
};

export const setUser = (user) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_PROCESS_SUCCESS, payload: user });
  }
}

export const logoutUser = () => {
  localStorage.clear()
  return (dispatch) => {
    dispatch({ type: LOGOUT_USER });
  }
}