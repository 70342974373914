import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";

import SectionTitle from "../../../components/section-title";
import Widget from "../../../components/widget";
import Validation from "../../../components/forms/validation";

const Index = () => {
  const location = useLocation();
  const activitiesList = useSelector((state) => state.activity.activities);
  const activityTypes = useSelector((state) => state.constants.activityTypes);
  const applicationNames = useSelector(
    (state) => state.constants.applicationNames
  );
  const distanceUnits = [
    { label: "kilometer", value: "km" },
    { label: "miles", value: "mi" },
  ];
  const path = location.pathname.split("/");

  const initialItem =
    activitiesList && activitiesList.length > 0
      ? activitiesList.find(
          (x) => x.id.toString() === path[path.length - 1].toString()
        )
      : null;
  const [data1, onSubmit1] = useState({...initialItem});

  let items = [
    {
      label: "Activity ID",
      disabled: true,
      name: "id",
      type: "text",
      placeholder: "Enter activity ID",
      value: data1 && data1.id,
    },
    {
      label: "Activity Date",
      error: { required: "Please enter activity date in DD-MM-YYYY" },
      name: "activityDate",
      type: "datepicker",
      placeholder: "Enter date of start for this activity",
      value:
        data1 && data1.activityDate,
    },
    {
      label: "Activity Type",
      error: {
        required: "Activity type is needed. Please pick one from the list",
      },
      name: "activityType",
      type: "select",
      placeholder: "Choose activity type",
      options: activityTypes,
      value: data1 && data1.activityType,
    },
    {
      label: "Application",
      error: {
        required: "Please choose the application used to track this activity",
      },
      name: "application",
      type: "select",
      placeholder: "Choose application name",
      options: applicationNames,
      value: data1 && data1.application,
    },
    {
      label: "Distance",
      error: { required: "Please enter distance" },
      name: "distance",
      type: "number",
      placeholder: "Please enter distance",
      value: data1 && data1.distance,
      onChangeText: (newText) => {
        onSubmit1({...data1, distance: newText })
      }
    },
    {
      label: "Distance Unit",
      error: { required: "Please choose distance unit" },
      name: "distanceUnit",
      type: "select",
      options: distanceUnits,
      placeholder: "Choose distance unit",
      value: "km",
    },
    {
      label: "Duration (hour)",
      error: { required: "Please enter duration (hour component)" },
      name: "durationHour",
      type: "number",
      placeholder: "Please enter duration (hour component)",
      value: data1 && data1.durationHour,
      onChangeText: (newText) => {
        onSubmit1({...data1, durationHour: newText })
      }
    },
    {
      label: "Duration (minute)",
      error: { required: "Please enter duration (minute component)" },
      name: "durationMinute",
      type: "number",
      placeholder: "Please enter duration (minute component)",
      value: data1 && data1.durationMinute,
      onChangeText: (newText) => {
        onSubmit1({...data1, durationMinute: newText })
      }
    },
    {
      label: "Duration (second)",
      error: { required: "Please enter duration (second component)" },
      name: "durationSecond",
      type: "number",
      placeholder: "Please enter duration (second component)",
      value: data1 && data1.durationSecond,
      onChangeText: (newText) => {
        onSubmit1({...data1, durationSecond: newText })
      }
    },
    {
      label: "Steps",
      name: "steps",
      type: "number",
      placeholder: "Please enter number of steps",
      value: data1 && data1.steps,
      onChangeText: (newText) => {
        onSubmit1({...data1, steps: newText })
      }
    },
    {
      label: "Calories",
      error: { required: "Please enter calories burned" },
      name: "calories",
      type: "number",
      placeholder: "Please enter calories burned in kcal",
      value: data1 && data1.calories,
      onChangeText: (newText) => {
        onSubmit1({...data1, calories: newText })
      }
    },
  ];

  return (
    <>
      <SectionTitle title="Activity" subtitle="Activity Verification" />

      <Widget
        title="Activity Management"
        description={
          <span>Fill in below form according to data provided by user</span>
        }
      >
        <div className="flex flex-col lg:flex-row w-full">
          <div className="flex flex-col lg:flex-row lg:flex-wrap w-full lg:space-x-4 lg:mr-4">
            <div className="w-full lg:p-2">
              <Validation items={items} onSubmit={onSubmit1} alerts={false} />
            </div>
            <div className="w-full lg:p-2">
              {data1 && <pre>{JSON.stringify(data1, null, 2)}</pre>}
            </div>
          </div>
          <div className="w-1/3">
            <img src={initialItem && initialItem.proofUrl} className="w-full" />
          </div>
        </div>
      </Widget>
    </>
  );
};
export default Index;
