import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SectionTitle from "../../components/section-title";
import Datatable from "../../components/datatable";
import clientGroups from "../../json/clientGroups.json";
import ClientList from '../../components/lists/client-list'

import Widget from "../../components/widget";
import Dropdown from "../../components/dropdowns/dropdown-1";
import DropdownItem from "../../components/dropdowns/dropdownItem";
import { FiEdit, FiTrash, FiChevronDown } from "react-icons/fi";
import moment from "moment";

const DropdownContent = ({ data, selectedItems }) => {
  const items = [
    {
      title: "Edit",
      icon: <FiEdit className="stroke-current text-xl" />,
      isEnabled: selectedItems.length === 1,
      link:
        selectedItems.length === 1
          ? "/master/edit/merchant/" + data[selectedItems[0]].id
          : "#",
    },
    {
      title: "Delete",
      icon: <FiTrash className="stroke-current text-xl" />,
      isEnabled: selectedItems.length >= 1,
      type: "modal",
      action: "delete",
    },
  ];

  return (
    <>
      <div>
        {items.map((item, i) => {
          return <DropdownItem key={i} {...item} />
        })}
      </div>
    </>
  );
};

const Simple = ({ data, setSelectedItems }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Group Name",
        accessor: "name",
      },
      {
        Header: "Member Count",
        accessor: "memberCount",
      },
      {
        Header: "Members",
        accessor: "members",
        Cell: (props) => (
          <ClientList
            items={props.value}
          />
        ),
      }
    ],
    []
  );
  //const data = React.useMemo(() => countries, [])

  return (
    <Datatable
      columns={columns}
      data={data}
      setSelectedData={(items) => setSelectedItems(items)}
    />
  );
};

const Index = () => {
  const data = React.useMemo(() => clientGroups, []);
  const [selectedItems, setSelectedItems] = useState([]);

  return (
    <>
      <SectionTitle title="Training Management" subtitle="Client Groups" />
      <Widget
        title={`Create group of clients for easier management, such as assigning program, workout type, etc.`}
        description={
          <Dropdown
            title={
              <div className="flex items-center -ml-4">
                Action
                <FiChevronDown className="ml-2 stroke-current" />
              </div>
            }
            toggleBgColor="bg-white"
            textColor="text-black"
            dropdownToggleClass="btn btn-default btn-outlined btn-outlined-indigo"
          >
            <DropdownContent data={data} selectedItems={selectedItems} />
          </Dropdown>
        }
        right={
          <div className="flex">
            <Link
              to={"/training/client-groups/add"}
            >
              <button type="button" className="rounded btn p-2 bg-aktivin-blue text-white">Add Group</button>
            </Link>

          </div>

        }
      >
        <Simple data={data} setSelectedItems={setSelectedItems} />
      </Widget>
    </>
  );
};

export default Index;
